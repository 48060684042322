<template>
  <div>
    <dashboard-layout>
      <div class="pt-7 px-4">
        <div class="container-fluid">
          <el-breadcrumb separator="/" class="mt-3 ml-3">
            <el-breadcrumb-item
              ><inertia-link href="/admin/dashboard"
                >Dashboard</inertia-link
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item><inertia-link href="/admin/booking">Bookings</inertia-link></el-breadcrumb-item>
            <el-breadcrumb-item>Booking Detail </el-breadcrumb-item>
          </el-breadcrumb>
           <div class="row mt-5">
               <div class="col-md-12 col-lg-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="">Enter Postcode</label>
                        <input-tag v-model="tags"></input-tag>
                    </div>
                      <div class="form-group">
                        <label for="">Default Delivery Time Interval (In Day)</label>
                        <input type="number" v-model="default_delivery" class="form-control">
                    </div>
                    <div class="form-group">
                        <button type="button" class="btn btn-primary" @click.prevent="save">Save</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4 mb-5">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <div class="search mb-5">
                    <div class="input-group mb-3">
                        <input type="text" v-model="search" class="form-control" placeholder="Search date by postcode" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append ">
                          <button type="button" class="btn btn-dark" @click="onSearch">Search</button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <FullCalendar class="calendar" :options="calendarOptions">
                      </FullCalendar>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dashboard-layout>

    <event-create :currentEvent="current_event" :optionList="tags" :booking="booking" :displayType="displayType"></event-create>
    <event-update
      v-if="booking_update_id"
      :key="booking_update_id"
      :updateEvent="update_event"
      :optionList="tags"
      :booking="booking"
      :displayType="displayType" 
    ></event-update>
  </div>
</template>

<script>
import DashboardLayout from "../../../layout/DashboardLayout";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import EventUpdate from "./EventUpdate.vue";
import EventCreate from "./EventCreate.vue";
import InputTag from 'vue-input-tag'
export default {
  props: ['booking'],
  components: {
    DashboardLayout,
    FullCalendar,
    EventCreate,
    EventUpdate,
    InputTag
  },
  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next,today",
          center: "title",
          right: "dayGridMonth,timeGridWeek",
        },
        initialView: "dayGridMonth",
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed

        viewDidMount: this.viewDisplay,
   
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventRemove: this.handleEventRemove,
        eventChange: this.handleEventChange,
        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
        events: [],
      },
      currentEvents: [],
      current_event: {},
      update_event: {},
      booking_update_id: 0,
      dialogBookingCreate: false,
      dialogBookingUpdate: false,
      tags: [],
      default_delivery: this.booking ? this.booking.default_delivery : 2,
      displayType: 'month',
      search: ''
    };
  },
  methods: {
    viewDisplay(data, search=""){
      let type = 'month';
      if (data.view.type == 'timeGridWeek') {
          type = 'week';
      }

      this.displayType = type;
      this.fetchAllBooking(type, search="")
    
    },
    onSearch(){
      this.fetchAllBooking(this.displayType, this.search)
    },
    fetchAllBooking(type, search){
        this.$store.dispatch("fetchAllBookings", {id: this.booking.id, type, search}).then((res) => {
          this.calendarOptions.events = res;
        });
    },
    handleEvents(selectInfo){
      console.log('selectInfo', selectInfo);
    },
    save(){
      let data = {
        postcodes: JSON.stringify(this.tags),
        default_delivery: this.default_delivery,
        booking_id: this.booking.id,
        _token: window.token
      };
        window.axios.post('/api/admin/save-booking-data', data).then(res => {
            if (res.data.status == 'error') {
               this.$notify({
                  title: 'Oops',
                  message: res.data.message,
                  type: 'error'
              });
            } else {
                console.log(res.data.booking);
                this.$notify({
                    title: 'Success',
                    message: `Successfully saved`,
                    type: 'success'
                });
            }
            
        })
    },

    toggleWeekends() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
    },

    handleDateSelect(selectInfo) {

      console.log('selectInfo', selectInfo);
      this.current_event = {
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
        displayType: this.displayType,
        selectInfo,
      };
      console.log(this.current_event);
      this.dialogBookingCreate = true;
    },

    handleEventClick(clickInfo) {
      this.booking_update_id = clickInfo.event.id;
      this.update_event = clickInfo.event;
      this.dialogBookingUpdate = true;
   
    },
    handleEventChange(event) {
      console.log("handleEventChange", event.event);
      this.currentEvents = event;
    },
    handleEventRemove(event) {
      console.log("handleEventRemove", event.event);
      this.currentEvents = event;
    },
    setEvent(events) {
      events.map(event => {
          console.log('event', event);
          this.calendarOptions.events = [...this.calendarOptions.events, event]
      })
      console.log('events', events);
    },
    updateEvent(events){
        events.map(event => {
            this.calendarOptions.events =  this.calendarOptions.events.map((data) => data.id == event.id ? event : data)
        })
    },
    removeEvent(id){
      this.calendarOptions.events = window._.filter(this.calendarOptions.events, event => event.id !== id);
    }
  },
  mounted() {
    this.fetchAllBooking('month', "");

    console.log(this.booking);
    window.axios.get(`/api/admin/postcodes/${this.booking.id}`).then(res => {
      res.data.map(code => {
        this.tags.push(code.postcode);
      })
    })
  },
};
</script>
<style></style>
