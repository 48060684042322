<template>
    <div class="booking">
        <el-dialog :title="`Update ${model.type}: (${this.updateEvent ? `${dateFormat(this.updateEvent.startStr)}` : ''})`" :close-on-click-modal="false" :visible.sync="$parent.dialogBookingUpdate">
                <ValidationObserver v-slot="{invalid}">
                <form action="">
                <div class="row">
                    <div class="form-group col-md-6">
                          <label for="">Collection / Delivery</label>
                          <select v-model="model.type" class="form-control">
                              <option value="collection">Collection</option>
                              <option value="delivery">Delivery</option>
                          </select>
                    </div>
                    <div class="form-group col-md-6">
                          <label for="">Recurring type</label>
                          <select v-model="model.recurring" class="form-control">
                              <option value="none">None</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                          </select>
                    </div>
                </div>
                 <div class="form-group">
                 
                        <label for="">
                            <span>Select Postcode
                                <span class="text-danger">*</span>
                            </span>
                        </label>
                       <multiselect v-model="model.postcode" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
       
                </div>
                <div class="form-group" v-if="recurring != 'none'">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" @click="model.change_future_event = !model.change_future_event">
                        <label class="form-check-label" for="gridCheck">
                            Do you want to apply these changes to all  future recurring events too?
                        </label>
                    </div>
                </div>
                <hr>
                <div class="mt-4 mb-4">
                   <div class="mb-4" v-for="(time, i) in model.times" :key="i">
                       <div class="d-flex justify-content-end align-items-center">
                           <span class="booking__remove-time" @click="removeTime(time.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                           </span>
                           
                        </div>
                        <div class="form-group">
                            <label for="">Price (Optional)</label>
                            <input type="number" v-model="time.price" placeholder="Enter Price" class="form-control">
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                 <ValidationProvider rules="required" v-slot="{errors}">
                                    <label for="">
                                        <span class="text-danger" v-if="errors[0]">Time From {{ errors[0] }}</span>
                                        <span v-else>Time From
                                            <span class="text-danger">*</span>
                                        </span>
                                    </label>
                                    <vue-timepicker format="h:mm a" v-model="time.from" input-class="form-control" input-width="100%"></vue-timepicker>
                                </ValidationProvider>
                            </div>
                            <div class="form-group col-md-6">
                                <ValidationProvider rules="required" v-slot="{errors}">
                                    <label for="">
                                        <span class="text-danger" v-if="errors[0]">Time To {{ errors[0] }}</span>
                                        <span v-else>Time To
                                            <span class="text-danger">*</span>
                                        </span>
                                    </label>
                                      <vue-timepicker format="h:mm a" v-model="time.to" input-class="form-control" input-width="100%"></vue-timepicker>
                                </ValidationProvider>
                            </div>
                        </div>
                       
                   </div>
                    <div class="form-group">
                        <button type="button" @click="addTime" class="btn btn-secondary">+ Add Time</button>
                    </div>
                </div>
                <div class="form-group mt-5">
                    <div class="row">
                        <div class="col-md-6">
                              <button type="button" class="btn btn-danger btn-block" @click.prevent="onDelete">Remove Date</button>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-primary btn-block" :disabled="invalid" @click.prevent="onSubmit">Save Update</button>
                        </div>
                     
                        
                   </div>
                </div>
            </form>
            </ValidationObserver>
        </el-dialog>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
const shortid = require('shortid');
import VueTimepicker from 'vue2-timepicker';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: 'field is required'
});
export default {
    props: ['updateEvent', 'optionList', 'booking'],
    components: {
        VueTimepicker,
        Multiselect,
        ValidationProvider,
        ValidationObserver
    },
    data(){
        return {
            id: null,
            recurring: 'none',
            model: {
                recurring: 'none',
                type: 'collection',
                times: [],
                change_future_event: false,
                postcode: [],
            },
             options: this.optionList
        }
    },
    methods: {
        addTag(){
            
        },
        onDelete(){
            let confirmDelete = prompt("Please enter YES if you want to delete date");
            if (confirmDelete == 'YES' || confirmDelete == 'yes') {
                this.$store.dispatch('deleteBooking', {id: this.id, change_future_event: this.model.change_future_event}).then(res => {

                    this.$notify({
                        title: 'Success',
                        message: `Successfully Deleted`,
                        type: 'success'
                    });

                    this.$parent.removeEvent(this.id);
                    

                }).catch(err => console.log(err))

                this.$parent.dialogBookingUpdate = false;
            }

        },
        addTime(){
            this.model.times.push({
                id: shortid.generate(),
                from: '',
                to: '',
                price: ''
            })
        },
        dateFormat(date){
            return moment(date).format('LL');
        },
        removeTime(id){
            this.model.times = window._.filter(this.model.times, time => time.id != id);
        },
        onSubmit(){
            let data = {
                recurring: this.model.recurring,
                times: JSON.stringify(this.model.times),
                start: this.updateEvent.start,
                end: this.updateEvent.end,
                type: this.model.type,
                backgroundColor: this.model.type == 'collection' ? '#38baf1' : ' #f6993f',
                event_id: shortid.generate(),
                change_future_event: this.model.change_future_event,
                postcode: JSON.stringify(this.model.postcode),
                booking_id: this.booking.id
            }
            console.log(data);
            this.$store.dispatch('updateBooking', {id: this.id, data}).then(res => {

                this.$notify({
                    title: 'Success',
                    message: `Successfully updated`,
                    type: 'success'
                });

                this.$parent.updateEvent(res);
                this.$parent.dialogBookingUpdate = false;

            }).catch(err => console.log(err))
        
    

        }
    },
    mounted() {
        this.$store.dispatch('fetchBooking', this.updateEvent.id).then(res => {
            console.log(res);
            this.id = res.[1].id;
            this.model.type = res.[1].type;
            this.recurring = res.[1].recurring;
            this.model.recurring = res.[1].recurring;
            this.model.times = JSON.parse(res.[1].event_times[0].data);
            let postcodes = res.[1].postcodes;
            postcodes.map(code => {
                this.model.postcode.push(code.postcode.postcode);  
            })
            console.log(res[1])
        })
    } 
}
</script>