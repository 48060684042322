

const state  = {
    vouchers: [],
    voucher: null,
}

const mutations = {
    ADD_VOUCHER(state, payload){
        state.vouchers = [payload, ...state.vouchers]
    },
    EDIT_VOUCHER(state, payload){
        state.vouchers = state.vouchers.map((voucher) => voucher.id == payload.id ? payload : voucher)
    },
    DELETE_VOUCHER(state, payload){
        state.vouchers = state.vouchers.filter((voucher) => voucher.id != payload.id)
    },
    VOUCHERS(state, payload){
        state.vouchers = payload;
    },
    VOUCHER(state, payload){
        state.voucher = payload;
    }
}

const actions = {
    fetchAllVouchers({commit}, payload){
            window.axios.post(`/api/admin/fetch-vouchers`, payload)
            .then((res) => {
                commit('VOUCHERS', res.data);
            }).catch((err) => {
                console.log(err.response.data.message);
            });
        
    },

    fetchVoucher({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/fetch-voucher/${id}`, {id})
            .then((res) => {
                commit('VOUCHER', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    addVoucher({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/add-voucher`, payload)
            .then((res) => {
                commit('ADD_VOUCHER', res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log('add error', err);
                reject(err);
            });
        });
    },

    updateVoucher({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.patch(`/api/admin/update-voucher/${payload.id}`, payload.data)
            .then((res) => {
                commit('EDIT_VOUCHER', res.data);
                resolve(res.data);

            }).catch((err) => {
                console.log('update error', err.response.data.errors);
                reject(err.response.data.errors);
            });
        });
    },
    editVoucher({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/vouchers/${id}/edit`)
            .then((res) => {
                commit('VOUCHER', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    deleteVoucher({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.delete(`/api/admin/delete-voucher/${payload.id}`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('DELETE_VOUCHER', res.data);
                resolve(res.data);
                // router.push('/admin/products');
            }).catch((err) => {
                console.log('add error', err.message);
                reject(err);
            });
        });
    }

}

const getters = {
    vouchers(state){
        return state.vouchers;
    },
    voucher(state){
        return state.voucher;
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}