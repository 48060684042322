<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="d-none icon icon-shape text-white rounded-circle shadow bg-gradient-red bg-gradient-orange bg-gradient-green bg-gradient-info"></div>
                <!-- Card stats -->
                <div class="row mt-5">
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Products"
                                    type="gradient-red"
                                    :sub-title="`${product_count}`"
                                    icon="ni ni-active-40"
                                    class="mb-4 mb-xl-0"
                        >

                         
                        </stats-card>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Orders"
                                    type="gradient-orange"
                                    :sub-title="`${order_count}`"
                                    icon="ni ni-chart-pie-35"
                                    class="mb-4 mb-xl-0"
                        >

                            
                        </stats-card>
                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Customers"
                                    type="gradient-green"
                                    :sub-title="`${customer_count}`"
                                    icon="ni ni-money-coins"
                                    class="mb-4 mb-xl-0"
                        >

                           
                        </stats-card>

                    </div>
                    <div class="col-xl-3 col-lg-6">
                        <stats-card title="Total Sales"
                                    type="gradient-info"
                                    :sub-title="`£${total()}`"
                                    icon="ni ni-chart-bar-32"
                                    class="mb-4 mb-xl-0"
                        >

                          
                        </stats-card>
                    </div>
                </div>


           <div class="mt-5">
            <div class="row">
                <div class="col-md-12 mb-5 ">
                    <card type="default" header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                                <h5 class="h3 text-white mb-0">Total Sales value</h5>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    

                                    <select class="form-control form-control-alternative" @input="changeSaleYear($event.target.value)">
                                        <option value="">Select Year</option>
                                        <option v-for="(year, i) in years" :value="year" :key="i" :selected="isCurrentYear(year) ? true : false">{{year}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <line-chart
                                :height="350"
                                ref="bigChart"
                                :chart-data="bigLineChart.chartData"
                        >
                        </line-chart>

                    </card>
                </div>

                <div class="col-md-12 mb-4">
                    <card header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-uppercase text-muted ls-1 mb-1">Overview</h6>
                                <h5 class="h3 mb-0">Total orders</h5>
                            </div>
                             <div class="col-2">
                                <div class="form-group">
                                    

                                    <select class="form-control form-control-alternative" @input="changeOrderYear($event.target.value)">
                                        <option value="">Select Year</option>
                                        <option v-for="(year, i) in years" :value="year" :key="i" :selected="isCurrentYear(year) ? true : false">{{year}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <bar-chart
                                :height="350"
                                ref="barChart"
                                :chart-data="redBarChart.chartData"
                        >
                        </bar-chart>
                    </card>
                </div>
            </div>
            <!-- End charts-->
            
        </div>
            <div class="mt-5">
                 <div class="row">
                    <div class="col-md-12 mb-5">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="m-0">Today's orders collection</h4>
                            </div>
                            <div class="card-body p-0">
                                <template v-if="orders_collection.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="orders_collection">
                                            <template slot="columns">
                                                <th>#</th>
                                                <th>Customer</th>
                                                <th>Date</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" class="align-middle">
                                                    #{{row.id}}
                                                </th>
                                                <td class="align-middle">
                                                    {{row.fullname}}
                                                </td>
                                                 <td class="align-middle">
                                                    {{timeDate(row.collection_date)}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.collection_time_from}}
                                                </td>
                                                 <td class="align-middle">
                                                    {{row.collection_time_to}}
                                                </td>
                                                <td class="align-middle">
                                                    £{{row.total}}
                                                </td>                                
                                                <td class="align-middle">
                                                    <inertia-link :href="`/admin/orders/${row.id}`" class="m-2">View</inertia-link>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-2 mb-2">
                                            <h4>No Orders Collection</h4>
                                        </div>
                                    </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-5">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="m-0">Today's orders delivery</h4>
                            </div>
                            <div class="card-body p-0">
                                 <template v-if="orders_delivery.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="orders_delivery">
                                            <template slot="columns">
                                                <th>#</th>
                                                <th>Customer</th>
                                                <th>Date</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" class="align-middle">
                                                    #{{row.id}}
                                                </th>
                                                <td class="align-middle">
                                                    {{row.fullname}}
                                                </td>
                                                 <td class="align-middle">
                                                    {{timeDate(row.delivery_date)}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.delivery_time_from}}
                                                </td>
                                                 <td class="align-middle">
                                                    {{row.delivery_time_to}}
                                                </td>
                                                <td class="align-middle">
                                                    £{{row.total}}
                                                </td>                                
                                                <td class="align-middle">
                                                    <inertia-link :href="`/admin/orders/${row.id}`" class="m-2">View</inertia-link>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-4 mb-4">
                                            <h4>No Orders Delivery</h4>
                                        </div>
                                    </template>
                                  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 mb-5 mb-xl-0">

                    </div>

                    <div class="col-xl-4">
                      
                    </div>
                </div>
                <!-- End charts-->

                <!--Tables-->
                <div class="row mt-5">
                
                </div>
                <!--End tables-->
            </div>
            </div>

        </dashboard-layout>

    </div>
</template>
<script>

import DashboardLayout from '../../layout/DashboardLayout';
import moment from "moment";
import * as chartConfigs from '../../components/Charts/config';
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';

export default {
    props: ['order_count', 'product_count', 'customer_count', 'orders', 'orders_collection', 'orders_delivery'],
    components: {
        DashboardLayout,
        LineChart,
        BarChart,
    },
    data() {
      return {
          year: '2021',
          years: [],
            bigLineChart: {
                chartData: {
                    datasets: [ {
                        label: 'Total Sales',
                        data: []
                    }],
                    labels:  [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                },
            },
            extraOptions: chartConfigs.blueChartOptions,
            redBarChart: {
                chartData: {
                    labels:  [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                    datasets: [{
                        label: 'Total Order',
                        data: []
                    }]
                }
            }
      };
    },
    methods: {
        total(){
            let total = 0;
            this.orders.map(order => {
                total += order.total;
            })

            return parseFloat(total).toFixed(2);
        },
        timeDate(date){
            return moment(date).format('MMMM Do YYYY');
        }, 
        isCurrentYear(year){
            let now = new Date()
            let yearCurrent = now.getFullYear()
            if(yearCurrent  == year){
                return true
            }else {
                return false
            }


       },
       changeOrderYear(year){
            window.axios.post('/api/admin/fetch-all-orders', {year}).then((res) => {
                this.redBarChart.chartData = {
                    datasets: [
                        {
                            label: 'Total Order',
                            data: res.data
                        }
                    ],
                    labels:  [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                };
                
            });
       },

       changeSaleYear(year){
           window.axios.post('/api/admin/fetch-total-sale', {year}).then(res => {
            let chartData = {
                datasets: [
                    {
                        label: 'Total Sales',
                        data: res.data.total_sales
                    }
                ],
                 labels:  [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
            };

            this.bigLineChart.chartData = chartData;

            let yearlist = [];
            for (const key in res.data.years) {
                yearlist.push(key);      
            }
            this.years = yearlist
        })

       }

    },
    mounted() {
        this.changeSaleYear(null);
        this.changeOrderYear(null);


    }
  };
</script>
<style></style>
