<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Products</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                               <div class="row justify-content-end">
                                    <div class="col-md-5">
                                        <div class="form-group input-group">
                                            <input placeholder="Search here" v-model="keyword" class="form-control"> 
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" @click="searchProduct">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                              <div class="card">
                                    <div class="card-header border-0">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h3 class="mb-0">All Products</h3>
                                        </div>
                                        <div class="col text-right" @click="addProduct">
                                            <inertia-link href="/admin/products/create" class="btn btn-primary">Add Product</inertia-link>
                                        </div>
                                    </div>
                                    </div>

                                    <template v-if="products.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="products">
                                            <template slot="columns">
                                                <th>Image</th>
                                                <th>Title</th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                                <th>Price</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" class="align-middle">
                                                    <img class="product-admin-image" :src="row.featureImage" />
                                                </th>
                                                <td class="align-middle">
                                                    <inertia-link :href="`/product/${row.id}/${row.slug}`" class="text-dark">{{row.title}}</inertia-link>
                                                </td>
                                                <td class="align-middle">
                                                    {{row.quantity}}
                                                </td>
                                                <td class="align-middle">
                                                    <badge v-if="row.status == 'publish'" pill type="success">{{row.status}}</badge>
                                                    <badge v-else pill type="warning">{{row.status}}</badge>
                                                </td>
                                                <td class="align-middle">
                                                    £{{row.price}}
                                                </td>
                                                <td class="align-middle">
                                                    <inertia-link :href="`/admin/products/${row.id}/edit/`" class="m-2"><i class="el-icon-edit admin-icon text-info"></i></inertia-link>
                                                    <a @click.prevent="dialogDelete(true, row.id)" :href="`/admin/products/delete/${row.id}`" class="m-2"><i class="el-icon-delete admin-icon"></i></a>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-5 mb-5">
                                            <h4>No Products</h4>
                                        </div>
                                    </template>
                                    <template v-if="products.length >= 10">
                                        <div class="text-center mt-5 mb-5">
                                            <button class="btn btn-primary" @click="showMoreProduct">Load More</button>
                                        </div>
                                    </template>

                                </div>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>
        <delete-product v-if="dialogDeleteId" :key="dialogDeleteId"></delete-product>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'
import DeleteVue from './Delete.vue';

  export default {
    components: {
        DashboardLayout,
        DeleteProduct: DeleteVue,
    },
    data() {
      return {
        data: [],
        dialogDeleteVisible: false,
        dialogDeleteId: 0,
        products: [],
        keyword: ''
      };
    },
    computed: {

        categories(){
            return this.$store.getters.categories;
        }
    },
    methods: {
        addProduct(){
            if (this.categories.length > 0) {
               
            } else {
                this.$notify({
                    title: 'Error',
                    message: `Oops seems like you don't have categories please create category.`,
                    type: 'error'
                });
            }
        },
        showMoreProduct(){

            this.$store.dispatch('fetchAllProducts', {limit: this.products.length + 10, keyword: this.keyword}).then(res => {
                console.log(res)
                this.products = res
            });
        },
        dialogDelete(status, id){
            this.dialogDeleteId = id;
            this.dialogDeleteVisible = true;
        },
        searchProduct(){
            this.$store.dispatch('fetchAllProducts', {limit: 10 , keyword: this.keyword}).then(res => {
                this.products = res
            });
        }
    },
    mounted() {
        this.$store.dispatch('fetchAllProducts', {limit: 10, keyword: this.keyword}).then(res => {
            console.log(res)
            this.products = res
        });
        this.$store.dispatch('fetchAllCategories', {limit: null, keyword: ''});
    }
  };
</script>
<style></style>
