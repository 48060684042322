require('./bootstrap');

require('moment');

import Vue from 'vue';
import store from './store/index';
import { InertiaApp } from '@inertiajs/inertia-vue';
import { InertiaForm } from 'laravel-jetstream';
import PortalVue from 'portal-vue';
import ArgonDashboard from './plugins/argon-dashboard';
import ElementUI, {Notification} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueTelInput from 'vue-tel-input';

Vue.use(ElementUI);
Vue.use(VueTelInput)
Vue.use(ArgonDashboard)
Vue.mixin({ methods: { route } });
Vue.use(InertiaApp);
Vue.use(InertiaForm);
Vue.use(PortalVue);

Vue.prototype.$notify = Notification;

const app = document.getElementById('app');

new Vue({
    store,
    render: (h) =>
        h(InertiaApp, {
            props: {
                initialPage: JSON.parse(app.dataset.page),
                resolveComponent: (name) => require(`./Pages/${name}`).default,
            },
        }),
}).$mount(app);
