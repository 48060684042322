<template>
    <div>
        <el-dialog title="Edit Category" :visible.sync="$parent.dialogEditVisible">
            <ValidationObserver v-slot="{invalid}">
                <form action="" method="post" @submit.prevent="onSubmit">
                    <ValidationProvider rules="required" v-slot="{errors}">
                    <div class="form-group">
                            <label for="">
                            <span class="text-danger block font-medium text-sm" v-if="errors[0]">{{ `Category Title ${errors[0]}` }}</span>
                            <span v-else class="block font-medium text-sm text-gray-500">Category Title 
                                <span class="text-danger">*</span>
                            </span>
                        </label>
                        <input type="text" class="form-control" placeholder="Enter Title" v-model="model.title">
                    </div>
                    </ValidationProvider>
                
                <div class="form-group">
                    <label for="" class="block font-medium text-sm text-gray-500">Category Description</label>
                    <textarea class="form-control" cols="30" rows="4" v-model="model.description"></textarea>
                </div>
                <div class="form-group">
                    <label for="" class="block font-medium text-sm text-gray-500">Parent Category (Optional)</label>
                    <select class="form-control" v-model="model.parent_id">
                        <option value="">Select Parent Category</option>
                        <option v-for="(category, i) in categories" :key="i" :checked="(model.parent_id && model.parent_id == category.id) ? true : false" :value="category.id">{{category.title}}</option>
                    </select>
                </div>
                <div class="form-group" v-if="!model.parent_id">
                    <label for="" class="block font-medium text-sm text-gray-500">Category Icon</label>
                    <el-upload
                        class="category-uploader"
                        action="#"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="handleIconUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="categoy-img">
                            <i v-else class="el-icon-picture category-uploader-icon"></i>
                        </el-upload>
                </div>
                <div class="form-group" v-else>
                    <label for="" class="block font-medium text-sm text-gray-500">Category FeatureImage</label>
                    <el-upload
                        class="category-uploader"
                        action="#"
                        :show-file-list="false"
                        :auto-upload="false"
                        :on-change="handleFeatureUpload">
                            <img v-if="featureImageUrl" :src="featureImageUrl" class="categoy-img">
                            <i v-else class="el-icon-picture category-uploader-icon"></i>
                        </el-upload>
                </div>
                <span slot="footer" class="dialog-footer">
                    <div class="form-group">
                    <button type="submit" :disabled="invalid" class="btn btn-primary">Save Category</button>
                </div>
                </span>
               
            </form>
        </ValidationObserver>
      
        </el-dialog>

    </div>
</template>
<style lang="scss">
  .category-uploader-icon {
    font-size: 38px;
    color: #8c939d;
    line-height: 60px;
    text-align: center;
  }
  .category-img {
    width: 100%;
    height: 60px;
    object-fit: cover;
    display: block;
  }

  
.category-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 60px;
}
.category-uploader .el-upload:hover {
    border-color: #38baf1;
}

.el-upload--picture-card:hover, .el-upload:focus {
    border-color: #38baf1 !important;
    color: #38baf1 !important;
}
</style>
<script>

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'field is required'
});
export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
      return {
          model: {
              title: '',
              description: '',
              icon: '',
              parent_id: '',
              featureImage: '',
          },
          imageUrl: '',
          error: '',
          categories: [],
      };
    },
    methods: {
        handleIconUpload(file, filelist) {
            console.log(file, filelist);
            this.imageUrl = URL.createObjectURL(file.raw);
            this.model.icon = file.raw;
        },
        
        handleFeatureUpload(file, filelist) {
            this.featureImageUrl = URL.createObjectURL(file.raw);
            this.model.featureImage = file.raw;
        },
        onSubmit(){
            if (this.model.title == '') {
                this.$notify({
                    title: 'Error',
                    message: `Title is required`,
                    type: 'error'
                });
            } else {
                let bodyFormData = new FormData();
                for (const property in this.model) {
                    bodyFormData.append(property, this.model[property])
                }
                bodyFormData.append('_method', 'PATCH');

                this.$store.dispatch('updateCategory', {id: this.$parent.dialogEditId, data: bodyFormData}).then((res) => {
                    this.$notify({
                        title: 'Success',
                        message: `Category ${res.title} has been successfully updated`,
                        type: 'success'
                    });
                    this.error = '';
                    this.$parent.dialogEditVisible = false;
                }).catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: `Oops Something went wrong!`,
                        type: 'error'
                    });
                });
            }
         
        }
    },
    mounted() {
        this.$store.dispatch('fetchCategory', this.$parent.dialogEditId).then(res => {
            this.model = {
                title: res.title,
                description: res.description,
                parent_id: res.parent_id
            }
            this.imageUrl = res.icon;
            this.featureImageUrl = res.featureImage;
        });

        window.axios.get('/api/admin/fetch-parent-categories').then(res => {
            this.categories = res.data;
        })
    }
  };
</script>
<style></style>
