<template>
    <div>
        <div class="top-nav">
            <div class="row justify-content-between">
                <div class="top-nav__menu">
                    <a href="/#orders" class="top-nav__menu--item">Order Now</a>
                    <a href="/#feedback" class="top-nav__menu--item">Feedback</a>
                </div>
                <div class="top-nav__menu">
                    <a href="/#contact" class="top-nav__menu--item">Call us: + 8312 1212 1212</a>
                    <a href="/#faq" class="top-nav__menu--item">Have any questions?</a>
                </div>
            </div>
        </div>
        <navbar></navbar>
        <div class="main-content">
            <slot></slot>
        </div>
        <footer-component v-if="footer"></footer-component>
    </div>
</template>

<script>
import FooterVue from "./Footer.vue";
import NavbarVue from "./Navbar.vue";
export default {
    props: ['footer'],
    components: {
        FooterComponent: FooterVue,
        Navbar: NavbarVue,
    }
}
</script>