<template>
        <div class="admin-login">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-7 mt-5">
                        <div class="card bg-secondary shadow border-0">
                    
                            <div class="card-body px-lg-5 py-lg-5">
                                <div class="d-flex justify-content-center align-items-center mb-5">
                                    <img src="/img/logo.png" style="width:200px" alt="">
                                </div>
                                <base-alert type="danger" class="mb-4" v-if="error">
                                    <span class="alert-inner--text text-white"><strong>Oops</strong> {{error}}</span>
                                    <button type="button" class="close" @click="error = ''"  aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </base-alert>
                                <form role="form">
                                    <base-input class="input-group-alternative mb-3"
                                                placeholder="Email"
                                                addon-left-icon="ni ni-email-83"
                                                v-model="model.email">
                                    </base-input>

                                    <base-input class="input-group-alternative"
                                                placeholder="Password"
                                                type="password"
                                                addon-left-icon="ni ni-lock-circle-open"
                                                v-model="model.password">
                                    </base-input>

                                    <base-checkbox class="custom-control-alternative">
                                        <span class="text-muted">Remember me</span>
                                    </base-checkbox>
                                    <div class="text-center">
                                        <base-button type="primary" @click.prevent="login" class="my-4 btn-block">Login</base-button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'login',
    data() {
      return {
        model: {
          email: '',
          password: ''
        },
        error: ''
      }
    },
    methods: {
        login(){
            window.axios.post('/admin/login-post', {...this.model, _token: window.token}).then(res => {
                console.log(res);
                window.location.href = '/admin/dashboard';
            }).catch(error => {
                this.error = error.response.data.message;
            });
        }
    }
  }
</script>
<style>
</style>
