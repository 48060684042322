<template>
    <div>
        <el-tabs type="border-card" v-model="activeName">
            <el-tab-pane v-for="(collection, i) in booking.collection" :key="i" :disabled="collection.data ? false : true" :name="collection.day_name">
                <div slot="label" :class="`d-flex flex-column justify-content-center align-items-center ${collection.data ? '' : 'is_disable'}`">
                    <h4>{{collection.day_name}}</h4>
                    <h2>{{collection.day_no}}</h2>
                </div>
                <div>
                    <base-alert type="danger" class="mb-4" v-if="error">
                        <span class="alert-inner--text text-white"><strong>Oops</strong> {{error}}</span>
                        <button type="button" class="close" @click="error = ''"  aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </base-alert>
                    <div v-if="collection.times">
                        <div class="row mt-4">
                            <div class="col-md-6 col-lg-6 col-sm-12 mb-3" v-for="(item, i) in collection.times" :key="i">
                                <div :class="`d-flex justify-content-between align-items-center booking__modal--collection__item ${(collection.selected_time && item.id == collection.selected_time.id) ? 'booking__modal--collection__item--active' : ''}`" @click="setCollectionDateTime(item.id, collection.data.id)">
                                    <div class="d-flex align-items-center ">
                                        <h4 class="m-0">{{item.from}}</h4>
                                        <div class="ml-2 mr-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                            </svg>
                                        </div>
                                        <h4 class="m-0">{{item.to}}</h4>
                                    </div>
                                    <div>
                                        <h4 class="m-0">{{item.price ? `£${item.price}` : 'Free!'}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    props: ['booking', 'name'],
    data(){
        return {
            activeName: this.name,
            error: ''
        }
    },
    methods: {
        setCollectionDateTime(timeId, eventId){
             window.axios.post('/api/set-collection-date-time', {timeId, eventId, type: 'collection', _token: window.token}).then(res => {
                if (res.data.status == 'error') {
                    this.error = res.data.message;
                } else {
                    this.error = '';
                    this.$parent.$parent.$parent.setBookingData(res.data.data);
                }
                
            }).catch(err => console.log(err))
        }
    },
    mounted() {
        console.log('booking collection', this.booking);
    }
}
</script>