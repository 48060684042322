<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid ">
                    <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item><inertia-link href="/admin/products">Products</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Edit Product</el-breadcrumb-item>
                    </el-breadcrumb>
                    <form @submit.prevent="onSubmit" action="" method="post">
                        <div class="row mt-4">
                            <div class="col-lg-8 col-md-8 col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                        <base-alert type="danger" class="d-flex justify-content-between align-items-center mb-4" v-if="errors.length > 0">
                                            <div class="d-flex flex-column">
                                                <span class="alert-inner--text text-white mb-2" v-for="(error, i) in errors" :key="i"><strong>Oops!</strong> {{error}}</span>
                                            </div>
                                            <button type="button" class="close" @click="errors = []">
                                                <span>&times;</span>
                                            </button>
                                        </base-alert>
                                        <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Product Title</label>
                                            <input type="text" class="form-control" placeholder="Enter Title" v-model="model.title">
                                        </div>
                                        <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Product Description</label>
                                            <quill-editor v-model="model.description"
                                                ref="myQuillEditor"
                                                :options="editorOption">
                                            </quill-editor>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label for="" class="block font-medium text-sm text-gray-500">Product Price</label>
                                                <input type="text" class="form-control" placeholder="Enter Price" v-model="model.price">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="" class="block font-medium text-sm text-gray-500">Product SalePrice</label>
                                                <input type="text" class="form-control" placeholder="Enter SalePrice" v-model="model.saleprice">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Product Quantity</label>
                                            <input type="number" class="form-control" placeholder="Enter Quantity" v-model="model.quantity">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="card">
                                    <div class="card-body">
                                         <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Category</label>
                                            <select name="" id="" @change="onSelectCategory($event)" v-model="model.category_id" class="form-control">
                                                <option value="">Select Category</option>
                                                <option v-for="(category, i) in categories" :key="i" :checked="category.id == model.category_id ? true : false" :value="category.id">{{category.title}}</option>
                                            </select>
                                        </div>
                                         <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Sub Category (Optional)</label>
                                            <select name="" id="" v-model="model.sub_category_id" class="form-control">
                                                <option value="">Select Sub Category</option>
                                                <option v-for="(category, i) in sub_categories" :key="i" :checked="(model.sub_category_id && model.sub_category_id == category.id) ? true : false" :value="category.id">{{category.title}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Product FeatureImage</label>
                                              <el-upload
                                                class="product-uploader"
                                                action="#"
                                                :show-file-list="false"
                                                :auto-upload="false"
                                                :on-change="handleProductUpload">
                                                    <img v-if="imageUrl" :src="imageUrl" class="product-img">
                                                    <i v-else class="el-icon-picture product-uploader-icon"></i>
                                                </el-upload>
                                        </div>
                                        <div class="form-group">
                                            <label for="" class="block font-medium text-sm text-gray-500">Product Image (optional)</label>
                                            <el-upload
                                                action="#"
                                                list-type="picture-card"
                                                :on-change="fileUpload"
                                                :file-list="model.images"
                                                :auto-upload="false">
                                                <i slot="default" class="el-icon-picture"></i>
                                                <div slot="file" slot-scope="{file}">
                                                <img
                                                    class="el-upload-list__item-thumbnail"
                                                    :src="file.url" alt=""
                                                >
                                                <span class="el-upload-list__item-actions">
                                                    <span
                                                        v-if="!disabled"
                                                        class="el-upload-list__item-delete"
                                                        @click="handleRemove(file)"
                                                    >
                                                    <i class="el-icon-delete"></i>
                                                    </span>
                                                </span>
                                                </div>
                                            </el-upload> 
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary btn-block">Save Change</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

        </dashboard-layout>

    </div>
</template>

<style lang="scss" scope>
  .product-uploader-icon {
    font-size: 78px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .product-img {
    width: 100%;
    height: 178px;
    object-fit: cover;
    display: block;
  }
  .el-upload--picture-card {
    width: 70px;
    height: 70px;
    line-height: 76px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 70px;
    height: 70px;
  }
</style>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
import { quillEditor } from 'vue-quill-editor'
import DashboardLayout from '../../../layout/DashboardLayout'

export default {
    props: ['product'],
    components: {
        DashboardLayout,
        quillEditor
    },
    data() {
      return {
          model: {
              title: '',
              description: '',
              quantity: '',
              price: '',
              saleprice: '',
              featureImage: '',
              category_id: '',
              sub_category_id: '',
              images: [],
              status: 'publish'
          },
          editorOption: {},
          imageUrl: '',
          sub_categories: [],
          categories: [],
          fileList: [],
          disabled: false,
          loading: false,
          errors: [],
      };
    },
    computed: {
        // categories(){
        //     return this.$store.getters.categories;
        // }
    },
    methods: {
        onSelectCategory(e){
            this.model.category_id = e.target.value;
            window.axios.post('/api/admin/fetch-sub-categories', {category_id: e.target.value}).then(res => {
                this.sub_categories = res.data;
            })
        },
        handleProductUpload(file, filelist) {
            console.log(file, filelist);
            this.imageUrl = URL.createObjectURL(file.raw);
            this.model.featureImage = file.raw;
        },
        handleRemove(file) {
            if (file.name == 'upload') {
                this.model.images = window._.filter(this.model.images, (o) => o.uid !== file.uid);
            }else {
                this.model.images = window._.filter(this.model.images, (o) => o.uid !== file.raw.uid);
            }    
        },
        fileUpload(file, fileList){
            this.fileList = fileList;
            this.model.images.push(file)
            console.log(file)
        },
        onSubmit(){
            this.loading = true;
            let bodyFormData = new FormData();
            
            for (const property in this.model) {
                if(property !== 'images'){
                    bodyFormData.append(property, this.model[property])
                }else {
                    this.model.images.map((image, i) => {
                        if(image.name == 'upload'){
                            bodyFormData.append(`images[${i}]`, image.url);
                        }else {
                            bodyFormData.append(`images[${i}]`, image.raw);
                        }
                    })
                }
            }

            bodyFormData.append('_method', 'PATCH');

            this.$store.dispatch('updateProduct', {id: this.product.id, data: bodyFormData}).then((res) => {
                this.$notify({
                    title: 'Success',
                    message: `Product ${res.title} has been successfully updated`,
                    type: 'success'
                });
                this.loading = false;
            }).catch(error => {
                this.errors = error;
                this.loading = false;
            });
        },
    },
    mounted() {
        window.axios.post('/api/admin/fetch-sub-categories', {category_id: this.product.category_id}).then(res => {
            this.sub_categories = res.data;
        })

        window.axios.get('/api/admin/fetch-parent-categories').then(res => {
            this.categories = res.data;
        })
        
        this.model.category_id = this.product.category_id;
        this.model.sub_category_id = this.product.sub_cat_id;
        this.model.title = this.product.title;
        this.model.description = this.product.description;
        this.model.quantity = this.product.quantity;
        this.model.price = this.product.price;
        this.model.saleprice = this.product.saleprice == null ? this.product.saleprice : 0;
        this.imageUrl = this.product.featureImage;
        this.model.featureImage = this.product.featureImage;
        this.model.status = this.product.status;
        console.log(JSON.parse(this.product.images))
        if(this.product.images){
            let img_list = []
            const images = JSON.parse(this.product.images);
            images.forEach((img) => {
                img_list.push({name: 'upload', url: img})
            });

            this.model.images = img_list;
            this.fileList = img_list;
        }
    }
  };
</script>
<style></style>
