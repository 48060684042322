<template>
    <div>
        <el-dialog
            title="Delete Product"
            :visible.sync="$parent.dialogDeleteVisible"
            width="30%"
            center>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="/img/trash.png" alt="">
                <span class="mt-4">Are you sure you want to delete this Product?</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <button type="button" class="btn btn-secondary" @click="$parent.dialogDeleteVisible = false">Cancel</button>
                <button type="button" class="btn btn-primary" @click="onDelete">Confirm Delete</button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
  export default {

    data() {
      return {
         
      };
    },
    methods: {
        onDelete(){
           this.$store.dispatch('deleteProduct', {id: this.$parent.dialogDeleteId}).then(res => {
                this.$notify({
                    title: 'Success',
                    message: `Product ${res.title} has been successfully deleted`,
                    type: 'success'
                });
                this.$parent.dialogDeleteVisible = false;
           });
        }
    },
    mounted() {
        
    }
  };
</script>
<style></style>
