<template>
    <div>
        <layout>
            <header class="header">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 col-sm-12">
                            <div>
                                <h1 class="header__title">Focus on what's matters</h1>
                                <h2 class="header__sub-title">We can do your laundry</h2>
                            </div>
                            <div class="d-flex flex-wrap algin-items-center">
                                <a href="/" class="btn btn-primary-outline btn-lg mr-4 mb-4 align-self-center">Download Our App</a>
                                <a href="/#orders" class="btn btn-primary btn-lg px-5 mb-4 align-self-center mr-4">Order Now</a>
                                <img class="align-self-center google-app-play-1 mb-4" src="/img/google_app_store.png" alt="">
                                <img class="google-app-play-2" src="/img/google-play-store.png" alt="">
                            </div>
                            <div class="mt-2">
                                <a href="/" class="mr-4">Available Playstore and Appstore</a>
                                <a href="/#orders">Or order via our web app</a>
                            </div>
                            <div class="header__overlay">
                                <img src="/img/header_circle.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12 col-sm-12">
                            <img class="header__img" src="/img/header_img.png" alt="">
                        </div>
                    </div>
                </div>
            </header>

            <div class="how-it-work" id="how-it-work">
                <div class="container-lg">
                    <div class="container">
                        <h2 class="how-it-work__title">How it works</h2>
                        <div class="row">
                            <div class="col-md-3 mb-4">
                                <div class="how-it-work__item d-flex justify-content-center flex-column align-items-center">
                                    <img src="/img/booking-teal.png" style="width: 125px;" alt="">
                                    <h4 class="mt-4">Book now</h4>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="how-it-work__item d-flex justify-content-center flex-column align-items-center">
                                    <img src="/img/time-slots-teal.png" style="width: 80px;" alt="">
                                    <h4 class="mt-4">Select your Schedule</h4>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="how-it-work__item d-flex justify-content-center flex-column align-items-center">
                                    <img src="/img/driver-teal.png" alt="">
                                    <h4 class="mt-4">Collection and Delivery</h4>
                                </div>
                            </div>
                            <div class="col-md-3 mb-4">
                                <div class="how-it-work__item d-flex justify-content-center flex-column align-items-center">
                                    <img src="/img/expert-cleaning-teal.png" style="width: 95px;" alt="">
                                    <h4 class="mt-4">Enjoy wearing your clothes</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order" id="orders">
                <div>
                    <h2 class="order__title">Order Now</h2>
                    <div class="order__content mt-5">
                        <div class="container-lg">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12" v-if="categories.length > 0">
                                       <div class="nav nav-pills mb-3" style="display:block !important;" id="pills-tab" role="tablist">
                                            <carousel :perPage="5" :paginationEnabled="false">
                                                <slide v-for="(category, i) in categories" :key="i">
                                                    <div class="nav-item m-2">
                                                        <a @click="selected = category.slug" :class="`nav-link ${selected == category.slug ? 'active' : ''}`" :id="`pills-${category.slug}-tab`" data-toggle="pill" :href="`#pills-${category.slug}`" role="tab" :aria-controls="`#pills-${category.slug}`" aria-selected="true">
                                                            <img :src="category.icon" alt="" style="width: 100%;height: inherit;">
                                                            <span>
                                                                {{category.title}}
                                                            </span>
                                                        </a>
                                                    </div>
                                                
                                                </slide>
                                            
                                            </carousel>
                                       </div>
                                       
                                        <div class="tab-content" id="pills-tabContent" v-if="categories.length > 0">

                                            <div v-for="(category) in category_products"  :key="category.slug" :class="`tab-pane fade ${selected == category.slug ? 'show active' : ''}`" :id="`pills-${category.slug}`" role="tabpanel" :aria-labelledby="`pills-${category.slug}-tab`">
                                                <div class="mt-5">
                                                    <div class="row">
                                                        <div class="col-md-6" v-for="(product, i) in category.products" :key="i * 100">
                                                           <product-item :product="product" :quantity="findCartQty(product.id)"></product-item>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        <div class="services" id="services">
                <h2 class="services__title">Services</h2>
                <div class="container-lg">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 mb-4">
                                <div class="card">
                                    <div class="card-body text-center services__item">
                                        <img src="/img/dry-cleaning.png" alt="">
                                        <div class="services__item--info mt-4">
                                            <h2>Dry Cleaning & Ironed Laundry</h2>
                                            <p>We dry clean or wash your items according to the care label. Clothes are ironed and returned on a hanger.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-4">
                                 <div class="card">
                                    <div class="card-body text-center services__item">
                                        <img src="/img/ironing.png" alt="">
                                        <div class="services__item--info mt-4">
                                            <h2>Iron Only</h2>
                                            <p>In select locations we offer an iron only service for shirts and blouses. You wash and dry, we iron!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-4">
                                 <div class="card">
                                    <div class="card-body text-center services__item">
                                        <img src="/img/wash.png" alt="">
                                        <div class="services__item--info mt-4">
                                            <h2>Wash, Tumble Dry & Fold</h2>
                                            <p>We wash at 30C, tumble dry and then neatly fold your clothes. Perfect for everyday items.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq" id="faq">
                <h2 class="faq__title">FAQ</h2>
                <div class="container-lg">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <el-collapse v-model="activeNames" accordion>
                                <el-collapse-item title="Consistency" name="1">
                                    <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
                                    <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Feedback" name="2">
                                    <div>Operation feedback: enable the users to clearly perceive their operations by style updates and interactive effects;</div>
                                    <div>Visual feedback: reflect current state by updating or rearranging elements of the page.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Efficiency" name="3">
                                    <div>Simplify the process: keep operating process simple and intuitive;</div>
                                    <div>Definite and clear: enunciate your intentions clearly so that the users can quickly understand and make decisions;</div>
                                    <div>Easy to identify: the interface should be straightforward, which helps the users to identify and frees them from memorizing and recalling.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Controllability" name="4">
                                    <div>Decision making: giving advices about operations is acceptable, but do not make decisions for the users;</div>
                                    <div>Controlled consequences: users should be granted the freedom to operate, including canceling, aborting or terminating current operation.</div>
                                </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact" id="contact-us">
                <div class="container-lg">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 mb-5">
                                <div class="contact__info">
                                    <h2>Contact Us</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quos impedit voluptatibus saepe animi illum nesciunt laboriosam enim eos nihil placeat, non rerum! Nemo sed aliquam a quasi, facere porro.</p>
                                    <div class="contact__info--list">
                                        <div class="contact__info--list__item d-flex align-items-center mb-4">
                                            <div class="contact__info--list__item--icon mr-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                                </svg>
                                            </div>
                                            <h4 class="m-0">+ 8312 1212 1212</h4>
                                        </div>
                                        <div class="contact__info--list__item d-flex align-items-center mb-4">
                                            <div class="contact__info--list__item--icon mr-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                                                </svg>
                                            </div>
                                            <h4 class="m-0">hello@example.com</h4>
                                        </div>
                                        <div class="contact__info--list__item d-flex align-items-center mb-4">
                                            <div class="contact__info--list__item--icon mr-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                </svg>
                                            </div>
                                            <h4 class="m-0">Your Location</h4>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-md-6 mb-5">
                                <div class="card">
                                    <div class="card-body">
                                        <div v-if="success" class="alert text-white alert-success alert-dismissible fade show" role="alert">
                                            {{success}}
                                            <button @click="success = ''" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        <ValidationObserver v-slot="{invalid}">
                                            <form @submit.prevent="onSendContact">
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <ValidationProvider rules="required" v-slot="{errors}">
                                                            <label for="">
                                                                <span class="text-danger" v-if="errors[0]">Name {{ errors[0] }}</span>
                                                                <span v-else>Name
                                                                    <span class="text-danger">*</span>
                                                                </span>
                                                            </label>
                                                            <input type="text" v-model="model.name" class="form-control" placeholder="Enter Name">
                                                        </ValidationProvider>   
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <ValidationProvider rules="required" v-slot="{errors}">
                                                            <label for="">
                                                                <span class="text-danger" v-if="errors[0]">Lastname {{ errors[0] }}</span>
                                                                <span v-else>Email
                                                                    <span class="text-danger">*</span>
                                                                </span>
                                                            </label>
                                                            <input type="email" v-model="model.email" class="form-control" placeholder="Enter Email">
                                                        </ValidationProvider>   
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <ValidationProvider rules="required" v-slot="{errors}">
                                                        <label for="">
                                                            <span class="text-danger" v-if="errors[0]">Subject {{ errors[0] }}</span>
                                                            <span v-else>Subject
                                                                <span class="text-danger">*</span>
                                                            </span>
                                                        </label>
                                                        <input type="text" v-model="model.subject" class="form-control" placeholder="Enter Subject">
                                                    </ValidationProvider>   
                                                </div>

                                                <div class="form-group">
                                                    <ValidationProvider rules="required" v-slot="{errors}">
                                                        <label for="">
                                                            <span class="text-danger" v-if="errors[0]">Message {{ errors[0] }}</span>
                                                            <span v-else>Message
                                                                <span class="text-danger">*</span>
                                                            </span>
                                                        </label>
                                                        <textarea class="form-control" v-model="model.message" placeholder="Enter Message" rows="4" cols=""></textarea>
                                                    </ValidationProvider>   
                                                </div>
                                            
                                                <div class="form-group">
                                                    <button type="submit" :disabled="invalid" class="btn btn-primary">Send</button>
                                                </div>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer-component></footer-component>
        </layout>
        <modal-postcode :postcode="postcode"></modal-postcode>
    </div>
</template>

<script>
import LayoutVue from "./../Layouts/Layout.vue";
import ModalPostcodeVue from './ModalPostcode.vue';
import { Carousel, Slide } from 'vue-carousel';
import ProductItem from './Product/ProductItem.vue';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import FooterVue from '../components/Footer.vue';

extend('required', {
  ...required,
  message: 'field is required'
});

export default {
    props: ['postcode', 'cart'],
    components: {
        Layout: LayoutVue,
        ModalPostcode: ModalPostcodeVue,
        Carousel,
        Slide,
        ProductItem,
        ValidationProvider,
        ValidationObserver,
        FooterComponent: FooterVue
    },
    data(){
        return {
            category_products: [],
            categories: [],
            selected: 'tops',
            activeNames: '1',
            success: '',
            model: {
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        }
    },
    computed: {
        carts(){
            return this.$store.getters.carts;
        }
    },
    methods: {
        onSendContact(){
            window.axios.post('/api/send-contact-message', this.model).then(res => {
                this.success = "Successfully send contact message"

                this.$notify({
                    title: 'Success',
                    message: `Successfully send contact message`,
                    type: 'success'
                });
                this.model =  {
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                }
            })
        },
        handleChange(val) {
            
        },
        findCartQty(id){
         
            const carts = this.carts.lenght > 0 ? this.carts : this.cart    
            let cart = window._.find(carts, cart => cart.id == id);
            return cart ? cart.qty : 0;

        },
        cartTotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })

            return parseFloat(total).toFixed(2);
        }
    },
    mounted() {

        this.$store.dispatch('fetchCart');
        window.axios.post('/api/fetch-categories', {type: 'web'}).then(res => {
            this.categories = res.data.categories;
            this.category_products = res.data.category_products;

        })
    }
}
</script>