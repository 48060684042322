<template>
    <div>
        <el-dialog
            title="Enter Your Postcode"
            :visible.sync="centerDialogVisible"
            width="30%"
            center>
            <form @submit.prevent="onSubmit">
                <div class="form-group">
                    <input type="text" name="postcode" v-model="code" class="form-control" placeholder="Enter Postcode">
                </div>
                <div class="form-group mb-2">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </form>
   
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['postcode'],
    data(){
        return {
            centerDialogVisible: false,
            code: '',
        }
            
    },
    methods: {
        onSubmit(){
            window.axios.post('/api/booking-postcode', {postcode: this.code}).then(res => {
                this.$notify({
                    title: 'Success',
                    message: `Successfully submited`,
                    type: 'success'
                });
                
                this.centerDialogVisible = false;
            })
        }
    },
    mounted(){
        if (!this.postcode) {
            this.centerDialogVisible = true;
        }
    }
}
</script>