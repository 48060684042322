<template>
    <div>
         <el-dialog
            title="Delete Booking"
            :visible.sync="$parent.dialogDeleteVisible"
            width="30%"
            center>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="/img/trash.png" alt="">
                <span class="mt-4">Are you sure you want to delete this Booking #{{id}}?</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <button type="button" class="btn btn-secondary" @click="$parent.dialogDeleteVisible = false">Cancel</button>
                <button type="button" class="btn btn-primary" @click="onDelete">Confirm Delete</button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data(){
        return {

        }
    },
    methods: {
        onDelete(){
            window.axios.post(`/api/admin/booking/remove`, {id: this.id}).then(res => {
                this.$notify({
                    title: 'Success',
                    message: `Successfully Remove`,
                    type: 'success'
                });
                this.$parent.deleteBooking(this.id);
                this.$parent.dialogDeleteVisible = false;
            })
        }
    }
}
</script>