<template>
    <div >
        <layout>
            <div class="booking">
                 <ValidationObserver v-slot="{invalid}">
                <form action="" method="post" @submit.prevent="onSubmit">
                    <div class="container">
                        <div class="mb-4 d-flex align-items-center">
                            <div class="mr-3">
                                <inertia-link href="/#orders">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                    </svg>
                                </inertia-link>
                            </div>
                            <el-breadcrumb separator="/" class="">
                                <el-breadcrumb-item><inertia-link href="/#orders">Orders</inertia-link></el-breadcrumb-item>
                                <el-breadcrumb-item>Booking</el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                        <div class="row">
                            <div class="col-md-7 col-lg-7 col-sm-12 mb-5">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <div class="booking__header">
                                                <h2>ADDRESS</h2>
                                            </div>
                                            <div class="booking__content">
                                                
                                                    <div class="row">
                                                        <div class="form-group col-md-6">
                                                            <ValidationProvider rules="required" v-slot="{errors}">
                                                                <label for="">
                                                                    <span class="text-danger" v-if="errors[0]">Line 1 {{ errors[0] }}</span>
                                                                    <span v-else>Line 1
                                                                        <span class="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                                <input type="text" v-model="model.address_1" class="form-control" placeholder="Address 1">
                                                            </ValidationProvider>
                                                       </div>
                                                        <div class="form-group col-md-6">
                                                            <label for="">Line 2</label>
                                                            <input type="text" v-model="model.address_2" class="form-control" placeholder="Address 2 (optional)">
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                         <ValidationProvider rules="required" v-slot="{errors}">
                                                         <label for="" v-if="!postcodeError">
                                                            <span class="text-danger" v-if="errors[0]">Postcode {{ errors[0] }}</span>
                                                            <span v-else>Postcode
                                                                <span class="text-danger">*</span>
                                                            </span>
                                                        </label>
                                                         <label for="" v-else>
                                                            <span class="text-danger">{{ postcodeError }}</span>
                                                        </label>
                                                        <input type="text" v-model="model.postcode" class="form-control" placeholder="Enter your postcode">
                                                         </ValidationProvider>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="">Delivery Instructions</label>
                                                    <textarea class="form-control" v-model="model.description" cols="30" rows="5" placeholder="Is there anything we need to know?"></textarea>
                                                    </div>
                                            
                                            </div>
                                            <div class="booking__header d-flex justify-content-between align-items-center">
                                                <h2>TIMES</h2>
                                                <inertia-link href="/#orders"><strong>EDIT</strong></inertia-link>
                                            </div>
                                            <div class="booking__content">
                                                <base-alert type="danger" class="mb-4" v-if="postcodeError">
                                                    <span class="alert-inner--text text-white"><strong>Oops</strong> {{postcodeError}}</span>
                                                    <button type="button" class="close" @click="postcodeError = ''"  aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </base-alert>
                                                <div class="row" v-if="bookingData && bookingData.selected_collection">
                                                    <div class="col-md-6">
                                                        <div class="booking__content--collection" @click="collectionDialogOpen">
                                                            <h2>Collection Time</h2>
                                                            <div class="mt-2">
                                                                <h4>{{bookingData.selected_collection.format_date}}</h4>
                                                                <div class="d-flex align-items-center">
                                                                    <h5 class="m-0">{{bookingData.selected_collection.time.from}}</h5>
                                                                    <div class="ml-2 mr-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                                        </svg>
                                                                    </div>
                                                                    <h5 class="m-0">{{bookingData.selected_collection.time.to}}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="booking__content--delivery"  @click="deliveryDialogOpen">
                                                            <h2>Delivery Time</h2>
                                                            <div class="mt-2">
                                                                <h4>{{bookingData.selected_delivery.format_date}}</h4>
                                                                <div class="d-flex align-items-center">
                                                                    <h5 class="m-0">{{bookingData.selected_delivery.time.from}}</h5>
                                                                    <div class="ml-2 mr-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                                        </svg>
                                                                    </div>
                                                                    <h5 class="m-0">{{bookingData.selected_delivery.time.to}}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 mb-5">
                                <div class="card card-dark">
                                    <div class="card-body">
                                        <div class="booking__header mb-3 d-flex justify-content-between align-items-center">
                                            <h2>ITEMS</h2>
                                            <inertia-link href="/#orders"><strong>EDIT</strong></inertia-link>
                                        </div>
                                        <div class="booking__content">
                                            <p>When ordering a Wash and Fold make sure to keep it separate from the rest of your order</p>
                                            <div class="booking__content--carts" v-if="carts.length > 0">
                                                <div v-for="(cart, i) in carts" :key="i"  class="booking__content--carts__item d-flex justify-content-between align-content-center">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <img class="mr-3" :src="cart.image" alt="">
                                                        <span class="mr-3">x{{cart.qty}}</span>
                                                        <h5 class="mr-3">{{cart.title}}</h5>
                                                    </div>
                                                    <div class="d-flex justify-content-end align-items-center">
                                                        <h2 class="mr-3 mb-0">£{{cart.price}}</h2>
                                                        <span class="booking__content--carts__item--icon" @click="removeCart(cart);">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-4 mb-4">
                                                <div class="form-group">
                                                    <textarea name="" v-model="model.instruction" id="" cols="30" rows="3" class="form-control" style="background-color: transparent" placeholder="Cleaning Instruction (Optional)"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="booking__header mb-3">
                                            <h2>CHECKOUT</h2>
                                        </div>
                                        <div class="booking__content">
                                             <base-alert type="success" v-if="success" >
                                                <span class="alert-inner--text text-white"><strong>Success!</strong> {{success}}</span>
                                                <button type="button" class="close" @click="success = ''">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </base-alert>
 
                                            <base-alert type="danger" v-if="error">
                                                <span class="alert-inner--text text-white"><strong>Oops!</strong> {{error}}</span>
                                                <button type="button" class="close" @click="error = ''" >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </base-alert>
                                            <div class="booking__content--coupon">
                                                <div class="input-group mb-3">
                                                    <input type="text" v-model="model.voucher" class="form-control" placeholder="Voucher Code" >
                                                    <button class="btn btn-primary" @click.prevent="setVoucher(discount_amount ? 'remove' : 'apply')">{{discount_amount ? 'Remove' : 'Apply'}}</button>
                                                </div>
                                            </div>
                                            <div class="booking__content--total mt-4">
                                                <div class="booking__content--total__item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h3>Subtotal</h3>
                                                        <p v-if="cartTotal() < 20">£20.00 minimum order</p>
                                                    </div>
                                                    <div>
                                                        <h3>£{{cartSubtotal()}}</h3>
                                                    </div>
                                                </div>
                                                <div v-if="discount_type" class="booking__content--total__item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h3 class="text-success">Discount</h3>
                                                     
                                                    </div>
                                                    <div>
                                                        <h3 class="text-success">-{{discount_type == 'fixedamount' ? parseFloat(discount_amount).toFixed(2) : `${discount_amount}%`}}</h3>
                                                    </div>
                                                </div>
                                                <div v-if="bookingData && bookingData.selected_collection" class="booking__content--total__item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h3>Collection Fee</h3>
                                                     
                                                    </div>
                                                    <div>
                                                        <h3 class="">{{bookingData.selected_collection.time.price ? `£${bookingData.selected_collection.time.price}` : 'Free'}}</h3>
                                                    </div>
                                                </div>
                                                <div v-if="bookingData && bookingData.selected_delivery" class="booking__content--total__item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h3>Delivery Fee</h3>
                                                     
                                                    </div>
                                                    <div>
                                                        <h3 class="">{{bookingData.selected_delivery.time.price ? `£${bookingData.selected_delivery.time.price}` : 'Free'}}</h3>
                                                    </div>
                                                </div>
                                                <div class="booking__content--total__item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h1>Total</h1>
                                                    </div>
                                                    <div>
                                                        <h1>£{{cartTotal()}}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <footer class="booking__footer d-flex justify-content-between align-items-center">
                            <div>
                                <inertia-link href="/#orders" class="btn btn-default">Back</inertia-link>
                            </div>
                            <div>
                                <button type="submit" :disabled="invalid ? invalid : btnDisable" class="btn btn-primary d-flex justify-content-center align-items-center">
                                    <span> Place Order</span>
                                    <span class="ml-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </span> 
                                </button>
                            </div>
                        </footer>
                    </div>
                 </form>
                 </ValidationObserver>
                <div v-if="bookingData" class="booking__modal">
                    <div class="el-dialog__body el-tabs--border-card el-tabs__nav el-tabs--border-card el-tabs__header el-tabs__item.is-disabled el-tabs__item el-tabs__nav-wrap"></div>
                    <el-dialog title="Collection time" :visible.sync="collectionDialog">
                        <div class="booking__modal--collection">
                            <div class="el-tabs--border-card el-tabs__header el-tabs--border-card el-tabs__header el-tabs__item is-active"></div>
                            <booking-collection :booking="bookingData" :name="collection_name" :setbooking="setBookingData"></booking-collection>
                        </div>
                    </el-dialog>
                    <el-dialog title="Delivery time" :visible.sync="deliveryDialog">
                        <div class="booking__modal--delivery">
                            <div class="el-tabs--border-card el-tabs__header el-tabs--border-card el-tabs__header el-tabs__item is-active"></div>
                            <booking-delivery :booking="bookingData" :name="delivery_name"></booking-delivery>
                        </div>
                    </el-dialog>
                </div>
            </div>
            <footer-component></footer-component>
        </layout>
    </div>
</template>

<script>
import Button from '../Jetstream/Button.vue';
import LayoutVue from "./../Layouts/Layout.vue";
import { postcodeValidator} from 'postcode-validator';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import BookingCollection from './Bookings/Collection.vue';
import BookingDelivery from './Bookings/Delivery.vue';
import FooterVue from '../Layouts/Footer.vue';

extend('required', {
  ...required,
  message: 'field is required'
});

export default {
    props: ['postcode'],
    components: {
        Layout: LayoutVue,
        ValidationProvider,
        ValidationObserver,
        BookingCollection,
        BookingDelivery,
        FooterComponent: FooterVue
    },
 
     data(){
        return {
            model: {
                address_1: this.$page.user ? this.$page.user.address : '',
                address_2: '',
                postcode: this.postcode ? this.postcode : '',
                description: '',
                instruction: '',
                voucher: ''
            },
            postcodeError: '',
            btnDisable: false,
            discount_amount: 0,
            discount_type: '',
            success: '',
            error: '',
            bookingData: null,
            collectionDialog: false,
            deliveryDialog: false,
            collection_name: '',
            delivery_name: ''
        }
    },
    watch: {
        'model.postcode': {
            deep: true,
            handler(val){
                this.checkPostCodeValid(val);  
            }
        }
    }, 
    computed: {
        carts(){
            return this.$store.getters.carts;
        },
        booking(){
            return this.$store.getters.booking;
        }
    },
    methods: {
        collectionDialogOpen(){
            this.collectionDialog = true;
            console.log('this.collectionDialog', this.collectionDialog);
        },
        deliveryDialogOpen(){
            this.deliveryDialog = true;
            console.log('this.deliveryDialog', this.deliveryDialog);
        },
        setVoucher(status){
            if (this.cartSubtotal() > 20) {
                 if (status == 'apply') {
                    window.axios.post('/api/set-voucher', {name: this.model.voucher, _token: window.token}).then(res => {
                    if (res.data.status == 'success') {
                        this.model.voucher = res.data.voucher.name;
                        this.discount_amount = res.data.voucher.amount;
                        this.discount_type = res.data.voucher.type
                        this.error = '';
                        this.success = 'Voucher Successfully Applied';
                    } else {
                        this.error = res.data.message;
                        this.success = '';
                    }
                })
                } else {
                    window.axios.get('/api/remove-voucher').then(res => {
                        this.discount_amount = 0;
                        this.discount_type = '';
                        this.model.voucher = '';
                        this.success = res.data.message;
                        this.error = ''
                    })
                }    
            } else {
                this.error = "You can't apply voucher with minimum order.";
                this.success = '';
            }
           
        },
        cartSubtotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })
            return total > 20 ?  parseFloat(total).toFixed(2) : parseFloat(20).toFixed(2);
        },

        cartTotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })

            total = total > 20 ? total : 20;

            if (this.bookingData) {
                console.log(this.bookingData.selected_collection.time.price, this.bookingData.selected_delivery.time.price);
                if (this.bookingData.selected_delivery.time.price) {
                    total = parseFloat(total) + parseFloat(this.bookingData.selected_delivery.time.price);
                }
                if(this.bookingData.selected_collection.time.price){
                    total = parseFloat(total) + parseFloat(this.bookingData.selected_collection.time.price);
                }
            }

            if (this.discount_type) {
                if (this.discount_type == 'fixedamount') {
                    total = total - this.discount_amount
                } else {
                    let subtotal = (total / 100) * this.discount_amount
                    total = total - subtotal
                }

                return parseFloat(total).toFixed(2);
            }

            return parseFloat(total).toFixed(2);
        },
        removeCart(cart){
            cart.qty--
            this.$store.dispatch('removeCart', cart)
        },
        setBookingData(data){
            console.log('data', data);
            this.bookingData = data;
            this.bookingData.collection.map(col => {
                if (col.is_selected) {
                    this.collection_name = col.day_name;
                }
            })

            this.bookingData.delivery.map(del => {
                if (del.is_selected) {
                    this.delivery_name = del.day_name;
                }
            })
        },
        checkPostCodeValid(val){
            if(val){
                if (postcodeValidator(val, 'GB')) {

                    window.axios.post('/api/booking-postcode', {postcode: val, _token: window.token}).then(res => {
                        if (res.data.status == 'error') {
                            this.postcodeError = res.data.message;
                            this.btnDisable = true;
                            this.bookingData = null;
                        } else {
                            this.bookingData = res.data.data
                            this.postcodeError = '';
                            this.btnDisable = false;

                            this.bookingData.collection.map(col => {
                                if (col.is_selected) {
                                    this.collection_name = col.day_name;
                                }
                            })

                             this.bookingData.delivery.map(del => {
                                if (del.is_selected) {
                                    this.delivery_name = del.day_name;
                                }
                            })
                        }
                    });

                    
                } else {
                    this.bookingData = null;
                    this.postcodeError = 'Postcode is invalid!';
                    this.btnDisable = true;
                }
            } else {
                this.postcodeError = ''
            }

           
        },
        onSubmit(){
            const data = {
                ...this.model,
                collection_fee: (this.bookingData && this.bookingData.selected_collection.time.price) ? this.bookingData.selected_collection.time.price : 0,
                delivery_fee: (this.bookingData && this.bookingData.selected_delivery.time.price) ? this.bookingData.selected_delivery.time.price : 0,
            }
            this.$store.dispatch('setBooking', {booking:data, _token: window.token});
        }
    },
    mounted(){
        this.$store.dispatch('fetchCart');
        window.axios.get('/api/booking').then(res => {
            console.log(res.data);
            if (res.data.postcode) {
                this.model = {
                    address_1: res.data.address_1,
                    address_2: res.data.address_2,
                    postcode: res.data.postcode,
                    description: res.data.description,
                    instruction: res.data.instruction,
                    voucher: res.data.voucher

                }
            }
        });

        window.axios.get('/api/get-voucher').then(res => {
             if (res.data) {
                 this.model.voucher = res.data.name;
                 this.discount_amount = res.data.amount;
                 this.discount_type = res.data.type
             }
        })
        if (this.postcode) {
            this.checkPostCodeValid(this.postcode);
        }
    }
}
</script>  