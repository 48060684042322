<template>
    <div class="booking">
       <el-dialog :title="`Add ${model.type}: (${this.currentEvent.selectInfo ? `${dateFormat(this.currentEvent.selectInfo.startStr)}` : ''})`" :close-on-click-modal="false" :visible.sync="$parent.dialogBookingCreate">
            <ValidationObserver v-slot="{invalid}">
            <form action="">
             
                <div class="row">
                    <div class="form-group col-md-6">
                          <label for="">Collection / Delivery</label>
                          <select @change="onChangeType($event)" class="form-control">
                              <option value="collection">Collection</option>
                              <option value="delivery">Delivery</option>
                          </select>
                    </div>
                    <div class="form-group col-md-6">
                          <label for="">Recurring type</label>
                          <select v-model="model.recurring" class="form-control">
                              <option value="none">None</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                          </select>
                    </div>
                </div>
                <div class="form-group">
                    <ValidationProvider rules="required" v-slot="{errors}">
                        <label for="">
                            <span class="text-danger" v-if="errors[0]">Postcode {{ errors[0] }}</span>
                            <span v-else>Select Postcode
                                <span class="text-danger">*</span>
                            </span>
                        </label>
                        <multiselect v-model="model.postcode" :options="optionList" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                    </ValidationProvider>
                </div>
                <hr>
                <div v-if="loading" class="d-flex justify-content-center mt-4 align-items-center">
                    <img src="/img/loading.gif" alt="" width="40">
                </div>
                <div v-else>
                    <div v-if="date_exist">
                        <base-alert type="danger" class="mb-4" v-if="date_exist">
                            <span class="alert-inner--text text-white"><strong>Oops</strong> {{model.type}} is already exists on this date</span>
                        </base-alert>
                    </div>
                    <div v-else>
                        <div class="mt-4 mb-4">
                        <div class="mb-4" v-for="(time, i) in model.times" :key="i">
                            <div class="d-flex justify-content-end align-items-center">
                                <span class="booking__remove-time" @click="removeTime(time.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                </span>
                                </div>
                                <div class="form-group">
                                    <label for="">Price (Optional)</label>
                                    <input type="number" v-model="time.price" placeholder="Enter Price" class="form-control">
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <ValidationProvider rules="required" v-slot="{errors}">
                                            <label for="">
                                                <span class="text-danger" v-if="errors[0]">Time From {{ errors[0] }}</span>
                                                <span v-else>Time From
                                                    <span class="text-danger">*</span>
                                                </span>
                                            </label>
                                            <vue-timepicker format="h:mm a" v-model="time.from" input-class="form-control" input-width="100%"></vue-timepicker>
                                        </ValidationProvider>
                                    
                                    </div>
                                    <div class="form-group col-md-6">
                                        <ValidationProvider rules="required" v-slot="{errors}">
                                            <label for="">
                                                <span class="text-danger" v-if="errors[0]">Time To {{ errors[0] }}</span>
                                                <span v-else>Time To
                                                    <span class="text-danger">*</span>
                                                </span>
                                            </label>
                                            <vue-timepicker format="h:mm a" v-model="time.to" input-class="form-control" input-width="100%"></vue-timepicker>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="button" @click="addTime" class="btn btn-secondary">+ Add Time</button>
                            </div>
                        </div>
                        <div class="form-group mt-5">
                            <button type="button" class="btn btn-primary btn-block" :disabled="invalid" @click.prevent="onSubmit">Save</button>
                        </div>
                    </div>
                </div>
            </form>
            </ValidationObserver>
        </el-dialog> 
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
const shortid = require('shortid');
import VueTimepicker from 'vue2-timepicker';
import moment from 'moment';
 import Multiselect from 'vue-multiselect'
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css';

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: 'field is required'
});

export default {
    props: ['currentEvent', 'optionList', 'booking', 'displayType'],
    components: {
        VueTimepicker,
        Multiselect,
        ValidationProvider,
        ValidationObserver
    },
    data(){
        return {
            model: {
                recurring: 'none',
                type: 'collection',
                times: [
                   
                ],
                postcode: [],
            },
            
            options: this.optionList,
            date_exist: false,
            loading: false
        }
    },
    watch: {
        currentEvent(){
            if (this.model.times.length == 0) {
                if (this.currentEvent.displayType == 'week') {
                    this.model.times.push( {
                        id: shortid.generate(),
                        from: this.currentEvent && this.currentEvent.displayType == 'week' ?  moment(this.currentEvent.start).format('h:mm a') : '',
                        to: this.currentEvent && this.currentEvent.displayType == 'week' ? moment(this.currentEvent.end).format('h:mm a') : '',
                        price: ''
                    })

                } else {
                    this.model.times.push( {
                        id: shortid.generate(),
                        from: '',
                        to: '',
                        price: ''
                    })
                }
            }

            this.checkDateAlreadyExist(this.currentEvent.start, this.model.type);
        }
    },
    methods: {
        addTag(){

        },
        onChangeType(e){
            this.model.type = e.target.value;
            this.checkDateAlreadyExist(this.currentEvent.start, this.model.type)
        },
        addTime(){
            this.model.times.push({
                id: shortid.generate(),
                from: '',
                to: '',
                price: ''
            })
        },
        removeTime(id){
            this.model.times = window._.filter(this.model.times, time => time.id != id);
        },
        dateFormat(date){
            return moment(date).format('LL');
        },
        onSubmit(){
            let data = {
                recurring: this.model.recurring,
                times: JSON.stringify(this.model.times),
                start: this.currentEvent.start,
                end: this.currentEvent.end,
                type: this.model.type,
                backgroundColor: this.model.type == 'collection' ? '#38baf1' : ' #f6993f',
                event_id: shortid.generate(),
                postcode: JSON.stringify(this.model.postcode),
                display_type: this.currentEvent.displayType,
                booking_id: this.booking.id
            } 
            console.log(data);

            this.$store.dispatch('addBooking', data).then(res => {
                this.$notify({
                    title: 'Success',
                    message: `Successfully added`,
                    type: 'success'
                });
                this.$parent.setEvent(res);

                this.model = {
                    title: 'collection',
                    recurring: 'none',
                    times: [
                        {
                            id: shortid.generate(),
                            from: '',
                            to: '',
                            price: ''
                        }
                    ]
                }
                this.$parent.dialogBookingCreate = false;
            }).catch(err => console.log(err))
        },
        checkDateAlreadyExist(date, type){
            this.loading = true;
            window.axios.post('/api/admin/check-date-already-exists', {date, type, booking_id: this.booking.id}).then(res => {
                this.loading = false;
                this.date_exist = res.data;
            })
        }
    },
    mounted() {
        console.log('this.displayType', this.displayType);
        
    },

}
</script>