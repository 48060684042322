<template>
    <div>
        <layout>
            <div class="container">
                <div class="w-full sm:max-w-md mt-6 px-6 py-2 bg-white shadow-md overflow-hidden sm:rounded-lg">
                    
                </div>
            </div>
        </layout>
    </div>
</template>

<script>
import LayoutVue from "../../Layouts/Layout.vue";
export default {
    components: {
        Layout: LayoutVue
    }
}
</script>