<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
              
                <div class="container-fluid">
                    <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Categories</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                               <div class="row justify-content-end">
                                    <div class="col-md-5">
                                        <div class="form-group input-group">
                                            <input placeholder="Search here" v-model="keyword" class="form-control"> 
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" @click="searchCategory">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                              <div class="card">
                                    <div class="card-header border-0">
                                        <div class="row align-items-center">
                                            <div class="col">
                                            <h3 class="mb-0">All Categories</h3>
                                            </div>
                                            <div class="col text-right">
                                                <a href="/admin/categories/create" @click.prevent="dialogCreateVisible = true" class="btn btn-primary">Add Category</a>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="categories.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="categories">
                                            <template slot="columns">
                                                <th>Icon</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Parent Category</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th class="align-middle" scope="row">
                                                    <template v-if="row.icon">
                                                        <img class="product-admin-image" :src="row.icon" />
                                                    </template>
                                                    <template v-else>
                                                        <img class="product-admin-image" :src="row.featureImage" />
                                                    </template>
                                                </th>
                                                <td class="align-middle">
                                                    <inertia-link :href="`/category/${row.slug}`" class="text-dark">{{row.title}}</inertia-link>
                                                </td>
                                                <td class="align-middle" style="white-space: normal;">
                                                    {{row.description}}
                                                </td>
                                                <td class="align-middle" style="white-space: normal;">
                                                    {{row.parent_category ? row.parent_category.title : ''}}
                                                </td>
                                                <td class="align-middle">
                                                    <a :href="`/admin/categories/edit/${row.id}`" @click.prevent="dialogEdit(true, row.id)" class="m-2"><i class="el-icon-edit admin-icon text-info"></i></a>
                                                    <a :href="`/admin/categories/delete/${row.id}`" @click.prevent="dialogDelete(true, row.id)" class="m-2"><i class="el-icon-delete admin-icon"></i></a>
                                                  
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-5 mb-5">
                                            <h4>No Categories</h4>
                                        </div>
                                    </template>
                                    <template v-if="categories.length >= 10">
                                        <div class="text-center mt-5 mb-5">
                                            <button class="btn btn-primary" @click="showMoreCategory">Load More</button>
                                        </div>
                                    </template>
                                </div>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>

        <create-category></create-category>
        <edit-category v-if="dialogEditId" :key="dialogEditId"></edit-category>
        <delete-category v-if="dialogDeleteId" :key="dialogDeleteId"></delete-category>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout';
import CreateCategory from './Create';
import EditCategory from './Edit';
import DeleteCategory from './Delete'
export default {
    components: {
        DashboardLayout,
        CreateCategory,
        EditCategory,
        DeleteCategory
    },
    data() {
      return {
          data: [],
          dialogCreateVisible: false,
          dialogEditVisible: false,
          dialogDeleteVisible: false,
          dialogEditId: 0,
          dialogDeleteId: 0,
          keyword: ''
      };
    },
    computed: {
        categories(){
            return this.$store.getters.categories;
        }
    },
    methods: {
        dialogEdit(status, id){
            this.dialogEditId = id;
            this.dialogEditVisible = true;
        },
        dialogDelete(status, id){
            this.dialogDeleteId = id;
            this.dialogDeleteVisible = true;
        },
        showMoreCategory(){
            this.$store.dispatch('fetchAllCategories', {limit: this.categories.length + 10, keyword: this.keyword, _token: window.token});
          
        },
        searchCategory(){
             this.$store.dispatch('fetchAllCategories', {limit: 10, keyword: this.keyword, _token: window.token});
        }
    },
    mounted() {
        this.$store.dispatch('fetchAllCategories', {limit: 10, keyword: this.keyword, _token: window.token});
    }
  };
</script>
<style></style>
