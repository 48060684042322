<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template slot="links" v-if="$page.user && $page.user.user_type == 'admin'">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-chart-bar-32 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item :link="{name: 'Products', icon: 'ni ni-bag-17 text-red', path: '/admin/products'}"/>
        <sidebar-item :link="{name: 'Category', icon: 'ni ni-bullet-list-67 text-dark', path: '/admin/categories'}"/>
        <sidebar-item :link="{name: 'Orders', icon: 'ni ni-cart text-yellow', path: '/admin/orders'}"/>
        <sidebar-item :link="{name: 'Booking', icon: 'ni ni-calendar-grid-58 text-blue', path: '/admin/booking'}"/>
        <sidebar-item :link="{name: 'Voucher', icon: 'ni ni-money-coins text-orange', path: '/admin/vouchers'}"/>
        <sidebar-item :link="{name: 'Customers', icon: 'ni ni-single-02 text-green', path: '/admin/customers'}"/>

      </template>
       <template slot="links" v-else>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-chart-bar-32 text-primary',
            path: '/dashboard'
          }"
        />

        <sidebar-item :link="{name: 'Orders', icon: 'ni ni-cart text-yellow', path: '/orders'}"/>
        <sidebar-item :link="{name: 'My Account', icon: 'ni ni-single-02 text-green', path: '/account'}"/>
  

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
          <!-- your content here -->
          <slot></slot>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
</style>
