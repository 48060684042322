<template>
    <div>
         <el-dialog
            title="Duplicate Booking"
            :visible.sync="$parent.dialogDuplicateVisible"
            width="30%"
            center>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="/img/duplicate.png" alt="">
                <span class="mt-4">Are you sure you want to Duplicate this Booking #{{id}}?</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <button type="button" class="btn btn-secondary" @click="$parent.dialogDuplicateVisible = false">Cancel</button>
                <button type="button" class="btn btn-primary" @click="onDuplicate">Confirm Duplicate</button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['id'],
    data(){
        return {

        }
    },
    methods: {
        onDuplicate(){
            window.axios.post(`/api/admin/booking/duplicate`, {id: this.id}).then(res => {
                this.$notify({
                    title: 'Success',
                    message: `Successfully Duplicate`,
                    type: 'success'
                });
                this.$parent.addBooking(res.data.booking);
                this.$parent.dialogDuplicateVisible = false;
            })
        }
    }
}
</script>