

const state  = {
    products: [],
    product: null,
    bookingDetail: null,
}

const mutations = {
    ADD_PRODUCT(state, payload){
        state.products = [payload, ...state.products]
    },
    EDIT_PRODUCT(state, payload){
        state.products = state.products.map((product) => product.id === payload.id ? payload : product)
    },
    DELETE_PRODUCT(state, payload){
        state.products = state.products.filter((product) => product.id != payload.id)
    },
    PRODUCTS(state, payload){
        state.products = payload;
    },
    PRODUCT(state, payload){
        state.product = payload;
    },
    SET_BOOKING(state, payload){
        state.bookingDetail = payload;
    }
}

const actions = {
    fetchAllProducts({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/fetch-products`, payload)
            .then((res) => {
                commit('PRODUCTS', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err.response.data.message);
            });
        });
       
        
    },

    fetchProduct({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/fetch-product/${id}`, {id})
            .then((res) => {
                commit('PRODUCT', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
    },
    addProduct({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/add-product`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('ADD_PRODUCT', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    updateProduct({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/update-product/${payload.id}`, payload.data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('EDIT_PRODUCT', payload);
                resolve(res.data);

            }).catch((err) => {
                console.log('update error', err.response.data.errors);
                reject(err.response.data.errors);
            });
        });
    },
    editProduct({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/products/${id}/edit`)
            .then((res) => {
                commit('PRODUCT', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    deleteProduct({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.delete(`/api/admin/delete-product/${payload.id}`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('DELETE_PRODUCT', res.data);
                resolve(res.data);
                // router.push('/admin/products');
            }).catch((err) => {
                console.log('add error', err.message);
                reject(err);
            });
        });
    },
    setBooking({commit}, payload){
        commit('SET_BOOKING', payload.booking);
        window.axios.post(`/api/booking`, payload)
        .then((res) => {
            console.log(res);
            window.location.href = '/checkout'
        }).catch((err) => {
            console.log('update error', err);
        });
    },
    fetchBooking({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/booking`)
            .then((res) => {
                commit('SET_BOOKING', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log('update error', err);
            });
        });
        
    }

}

const getters = {
    products(state){
        return state.products;
    },
    product(state){
        return state.product;
    },
    booking(state){
        return state.bookingDetail;
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}