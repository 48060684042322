

const state  = {
    categories: [],
    category: null,
}

const mutations = {
    ADD_CATEGORY(state, payload){
        state.categories = [payload, ...state.categories]
    },
    EDIT_CATEGORY(state, payload){
        state.categories = state.categories.map((category) => category.id == payload.id ? payload : category)
    },
    DELETE_CATEGORY(state, payload){
        state.categories = state.categories.filter((category) => category.id != payload.id)
    },
    CATEGORIES(state, payload){
        state.categories = payload;
    },
    CATEGORY(state, payload){
        state.category = payload;
    }
}

const actions = {
    fetchAllCategories({commit}, payload){
        window.axios.post(`/api/admin/fetch-categories`, payload)
        .then((res) => {
            commit('CATEGORIES', res.data);
        }).catch((err) => {
            console.log(err.response.data.message);
        });
        
    },

    fetchCategory({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/fetch-category/${id}`, {id})
            .then((res) => {
                commit('CATEGORY', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    addCategory({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/add-category`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('ADD_CATEGORY', res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log('add error', err);
                reject(err);
            });
        });
    },

    updateCategory({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/update-category/${payload.id}`, payload.data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('EDIT_CATEGORY', res.data);
                resolve(res.data);

            }).catch((err) => {
                console.log('update error', err.response.data.errors);
                reject(err.response.data.errors);
            });
        });
    },
    editCategory({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/categories/${id}/edit`)
            .then((res) => {
                commit('CATEGORY', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    deleteCategory({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.delete(`/api/admin/delete-category/${payload.id}`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('DELETE_CATEGORY', res.data);
                resolve(res.data);
                // router.push('/admin/products');
            }).catch((err) => {
                console.log('add error', err.message);
                reject(err);
            });
        });
    }

}

const getters = {
    categories(state){
        console.log(state.categories);
        return state.categories;
    },
    category(state){
        return state.category;
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}