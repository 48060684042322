<template>
    <div class="product">
        <layout :footer="false">
            <div style="margin-bottom: 7rem">
                <div v-if="!category">
                    <div class="product__categories">
                        <div v-for="(category, i) in categories" :key="i" :class="`product__categories--item ${i == 0 ? 'product__categories--item__active' : ''}`">
                            <inertia-link :href="`/items/${category.slug}`" :id="`tab-${category.slug}`" role="tab" :aria-controls="category.slug" :aria-selected="i == 0 ? true : false">
                                <img :src="category.icon" alt="">
                                <h6>{{category.title}}</h6>
                            </inertia-link>
                        </div>
                    </div>
                    <div class="container">
                        <div class="tab-content mt-5" id="myTabContent">
                            <div v-for="(category, i) in categories" :key="i" :class="`tab-pane fade ${i == 0 ? 'show active' : ''}`"  :id="category.slug" role="tabpanel" :aria-labelledby="`tab-${category.slug}`">
                                <div class="row">
                                    <div v-for="(product, i) in category.products" :key="i" class="col-lg-6 col-md-6 col-sm-12">
                                        <product-item :product="product" :quantity="findCartQty(product.id)"></product-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div v-else>
                     <div class="product__categories">
                        <div v-for="(cat, i) in categories" :key="i" :class="`product__categories--item ${cat.id == category.id ? 'product__categories--item__active' : ''}`">
                            <inertia-link :href="`/items/${cat.slug}`" :id="`tab-${cat.slug}`" role="tab" :aria-controls="cat.slug" :aria-selected="cat.id == category.id ? true : false">
                                <img :src="cat.icon" alt="">
                                <h6>{{cat.title}}</h6>
                            </inertia-link>
                        </div>
                    </div>
                    <div class="container" >
                         <div class="tab-content mt-5 " id="myTabContent">
                            <div class="tab-pane fade show active"  :id="category.slug" role="tabpanel" :aria-labelledby="`tab-${category.slug}`">
                                <div class="row">
                                    <div v-for="(product, i) in category.products" :key="i" class="col-lg-6 col-md-6 col-sm-12">
                                        <product-item :product="product" :quantity="findCartQty(product.id)"></product-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <footer class="footer">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="footer--support"></div>
                        <div class="footer--info">
                            <h1 class="footer--info__total mb-4" v-if="carts.length > 0"><small>Total:</small> £{{cartTotal()}}</h1>
                            <inertia-link class="btn btn-primary d-flex justify-content-center align-items-center" href="/booking">
                                <span> {{carts.length > 0 ? 'Your Basket' : 'Skip Item Selection'}}</span>
                                <span class="ml-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </span> 
                            </inertia-link>
                        </div>
                    </div>
                </div>
            </footer>
        </layout>
    </div>
</template>

<script>
import LayoutVue from "./../Layouts/Layout.vue";
import ProductItemVue from './Product/ProductItem.vue';
export default {
    props: ['categories', 'category', 'cart'],
    components: {
        Layout: LayoutVue,
        ProductItem: ProductItemVue
    },
    data(){
        return {

        }
    },
    computed: {
        carts(){
            return this.$store.getters.carts;
        }
    },
    methods: {
        findCartQty(id){
            console.log('this.cart', this.carts);
            const carts = this.carts.lenght > 0 ? this.carts : this.cart    
            let cart = window._.find(carts, cart => cart.id == id);
            return cart ? cart.qty : 0;

        },
        cartTotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })

            return parseFloat(total).toFixed(2);
        }
    },
    mounted(){
        this.$store.dispatch('fetchCart');
    }
}
</script>