<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
              
                <div class="container-fluid">
                    <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Bookings</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                              <div class="card">
                                    <div class="card-header border-0">
                                        <div class="row align-items-center">
                                            <div class="col">
                                            <h3 class="mb-0">All Booking Schedules</h3>
                                            </div>
                                            <div class="col text-right">
                                                <a href="/admin/categories/create" @click.prevent="dialogCreateVisible = true" class="btn btn-primary">Add Booking  </a>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="data.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="data">
                                            <template slot="columns">
                                                <th>id</th>
                                                <th>postcodes</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th class="align-middle" scope="row">
                                                   {{row.id}}
                                                </th>
                                                <td class="align-middle">
                                                    <span class="mr-2 badge badge-info" style="font-size: 13px; font-weight: 500" v-for="(booking, i) in row.booking_postcodes" :key="i">{{booking.postcode}}</span>
                                                   
                                                </td>
                                              
                                                
                                                <td class="align-middle">
                                                    <a :href="`/admin/booking/${row.id}`" class="m-2"><i class="el-icon-edit admin-icon text-info"></i></a>
                                                    <a href="#" @click.prevent="dialogDuplicate(true, row.id)"><i class="el-icon-document-copy text-warning"></i></a>
                                                    <a :href="`/admin/booking/delete/${row.id}`" @click.prevent="dialogDelete(true, row.id)" class="m-2"><i class="el-icon-delete admin-icon"></i></a>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-5 mb-5">
                                            <h4>No Booking Schedules</h4>
                                        </div>
                                    </template>
                                    <template v-if="data.length >= 10">
                                        <div class="text-center mt-5 mb-5">
                                            <button class="btn btn-primary">Load More</button>
                                        </div>
                                    </template>
                                </div>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>
        <booking-create></booking-create>
        <booking-delete :key="bookingDeleteId" :id="bookingDeleteId" v-if="bookingDeleteId"></booking-delete>
        <booking-duplicate :key="bookingDuplicateId" :id="bookingDuplicateId" v-if="bookingDuplicateId"></booking-duplicate>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout';
import BookingCreate from "./BookingCreate";
import BookingDelete from './BookingDelete.vue';
import BookingDuplicate from './DuplicateBooking.vue';
export default {
    props: ['booking'],
    components: {
        DashboardLayout,
        BookingCreate,
        BookingDelete,
        BookingDuplicate
    },
    data() {
      return {
        data: this.booking,
        dialogCreateVisible: false,
        dialogDeleteVisible: false,
        dialogDuplicateVisible: false,
        bookingDeleteId: 0,
        bookingDuplicateId: 0
      };
    },
    computed: {
       
    },
    methods: {
        dialogDelete(status, id){
            this.dialogDeleteVisible = status;
            this.bookingDeleteId = id;
        },

        dialogDuplicate(status, id){
            this.dialogDuplicateVisible = status;
            this.bookingDuplicateId = id;
        },

        deleteBooking(id){
            this.data = window._.filter(this.data, res => res.id !== id);
        },

        addBooking(data){
            this.data = [...this.data, data];
        }
      
    },
    mounted() {
        
    }
  };
</script>
<style></style>
