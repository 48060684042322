import Vue from 'vue';
import Vuex from 'vuex';
import product from './modules/product';
import category from './modules/category';
import cart from './modules/cart';
import voucher from './modules/voucher';
import order from './modules/order';
import booking from './modules/booking';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},

    mutations: {},

    actions: {},

    getters: {},

    modules: {
        product,
        booking,
        category,
        cart,
        voucher,
        order
    }

});