<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Customers</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                              <div class="card">
                                    <div class="card-header border-0">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h3 class="mb-0">All Customers</h3>
                                        </div>
                                       <div class="col-md-5">
                                            <div class="form-group input-group">
                                                <input placeholder="Search here" v-model="keyword" class="form-control"> 
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" @click="searchCustomer">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                    <template v-if="customer_list.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="customer_list">
                                            <template slot="columns">
                                                <th>Customer Id</th>
                                                <th>Firstname</th>
                                                <th>Lastname</th>
                                                <th>Email</th>
                                                <th>Postcode</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" class="align-middle">
                                                    #{{row.id}}
                                                </th>
                                                <td class="align-middle">
                                                    {{row.name}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.lname}}
                                                </td>
                                                    <td class="align-middle">
                                                    {{row.email}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.postcode}}
                                                </td>                                
                                                <td class="align-middle">
                                                    <inertia-link :href="`/admin/customers/${row.id}`" class="m-2">View</inertia-link>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-5 mb-5">
                                            <h4>No Customers</h4>
                                        </div>
                                    </template>
                                    <template v-if="customer_list.length >= 10">
                                        <div class="text-center mt-5 mb-5">
                                            <button class="btn btn-primary" @click="showMoreCustomer">Load More</button>
                                        </div>
                                    </template>

                                </div>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'

export default {
    props: ['customers'],
    components: {
        DashboardLayout,

    },
    data() {
      return {   
          customer_list: this.customers,
          keyword: ''
      };
    },
    computed: {
        
    },
    methods: {
        showMoreCustomer(){
            window.axios.post('/admin/customer-load-more', {
                _token: window.token, 
                limit: this.customers.length + 10,
                keyword: this.keyword
            }).then(res => {
                this.customer_list = res.data;
            })
        },
        searchCustomer(){
             window.axios.post('/admin/customer-load-more', {
                _token: window.token, 
                limit: this.customers.length + 10,
                keyword: this.keyword
            }).then(res => {
                this.customer_list = res.data;
            })
        },
    },
    mounted() {
      
    }
  };
</script>
<style></style>
