<template>
    <div class="mb-4">
        <div class="card card-black">
        <el-carousel height="250px" width="100%">
            <el-carousel-item v-for="item in images(product.featureImage, product.images)" :key="item">
                <img :src="item" alt="" style="width: 100%;height: inherit;">
            </el-carousel-item>
        </el-carousel>
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center border-bottom">
                <div class="px-3 py-3">
                    <h4>{{product.title}}</h4>
                </div>
                <div class="d-flex align-items-center">
                    <div class="py-3">
                        <div v-if="qty">
                            <h4><span class="product__item--qty">{{qty}}x</span></h4>
                        </div>
                    </div>
                    <div class="d-flex mx-3">
                         <div class="d-flex justify-content-end align-items-end">
                            <div v-if="qty" class="py-3">
                                <button @click="minusQty" class="product__item--add">-</button>
                            </div>
                            <span class="mx-3" style="width: 1px; background-color: rgb(255, 255, 255, 0.5); height: 100%"></span>
                            <div class="py-3">
                                <button class="product__item--add" @click="addQty">+</button>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 py-3">
                        <h4>£{{parseFloat(product.price).toFixed(2)}}</h4>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center ">
                <div class="px-3 py-3">
                    <p class="text-white" v-html="product.description"></p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="card product__item">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-3 col-md-12">
                    <img :src="product.featureImage" class="product__item--img" alt="">
                </div>
                <div class="col-lg-9 col-md-12">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2 class="product__item--title">{{product.title}}</h2>
                        <h1>£{{parseFloat(product.price).toFixed(2)}}</h1>
                    </div>
                    <div class="product__item--content" v-html="product.description"></div>
                    <div class="d-flex justify-content-end align-items-end">
                        <div v-if="qty">
                            <button @click="minusQty" class="product__item--add">-</button>
                        </div>
                        <div v-if="qty">
                            <span class="product__item--qty">{{qty}}</span>
                        </div>
                        <div>
                            <button class="product__item--add" @click="addQty">+</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    </div>
</template>

<script>
export default {
    props: ['product', 'quantity'],
    data(){
        return {
            qty: this.quantity
        }
    },
    computed: {
        carts(){
            return this.$store.getters.carts;
        }
    },
    methods: {
        addQty(){
            this.qty++
            this.$store.dispatch('addCart', {
                id: this.product.id, 
                title: this.product.title,
                image: this.product.featureImage,  
                qty: this.qty, 
                price: this.product.price,

            })

        },
        minusQty(){
            this.qty--
            this.$store.dispatch('removeCart', {
                id: this.product.id, 
                title: this.product.title,
                image: this.product.featureImage,  
                qty: this.qty, 
                price: this.product.price,
            })
        },

        images(featureImage, images){
            let image = [featureImage];
            let image_list = JSON.parse(images);
            image_list.map(img => {
                image.push(img);
            })

            return image;
        }
        
    },
    mounted(){

    }
}
</script>