<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4 order">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item><inertia-link href="/admin/orders">Orders</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Order Detail</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                            <card shadow type="secondary">
                                 <div slot="header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h3>ORDER #: {{order.id}}</h3>
                                            <h5>Date: {{orderDate(order.created_at)}}</h5>
                                        </div>
                                        <div v-if="order_detail">
                                            <badge v-if="order_detail.status == 'complete'" class="badge-lg" type="success">{{order_detail.status}}</badge>
                                            <badge v-if="order_detail.status == 'on-delivery'" class="badge-lg" type="info">{{order_detail.status}}</badge>
                                            <badge v-if="order_detail.status == 'pending'" class="badge-lg" type="warning">{{order_detail.status}}</badge>
                                            <badge v-if="order_detail.status == 'reject'" class="badge-lg" type="danger">{{order_detail.status}}</badge>
                                            <badge v-if="order_detail.status == 'refund'" class="badge-lg" type="default">{{order_detail.status}}</badge>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="order_detail">
                                    <div class="">
                                        <div class="">
                                            <div class="order__products">
                                                <div class="table-responsive">
                                                    <div>
                                                        <table class="table align-items-center">
                                                            <thead class="thead-light">
                                                                <tr>
                                                                    <th scope="col" class="sort" data-sort="name">Product</th>
                                                                    <th scope="col" class="sort" data-sort="budget"></th>
                                                                    <th scope="col" class="sort" data-sort="status">Quantity</th>
                                                                    <th scope="col" class="sort" data-sort="completion">Price</th>
                                                                    <th scope="col">Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="list">
                                                                <tr v-for="(order_product, i) in order_detail.order_products" :key="i" class="order__products--item">
                                                                    <template v-if="order_product.product">
                                                                        <th scope="row">
                                                                            <img :src="order_product.product.featureImage" alt="" srcset="">
                                                                        </th>
                                                                        <td class="budget">
                                                                            {{order_product.product.title}}
                                                                        </td>
                                                                        <td>
                                                                            x{{order_product.qty ? order_product.qty : 0}}
                                                                        </td>
                                                                        <td>
                                                                            £{{order_product.product.price}}
                                                                        </td>
                                                                        <td>
                                                                            £{{order_product.qty ? order_product.qty * order_product.product.price : order_product.product.price}}
                                                                        </td>
                                                                    </template>
                                                                    
                                                                </tr>
                                                                <tr class="order__products--item">
                                                                    <th scope="row">
                                                                        <h4>Subtotal:</h4>
                                                                        <p v-if="order.subtotal < 20 && !order.discount">£20.00 minimum order</p>
                                                                    </th>
                                                                    <td class="budget">
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       <h3>£{{order.subtotal < 20 && !order.discount ? 20 : order.subtotal}}</h3>
                                                                    </td>
                                                                </tr>
                                                                <tr class="order__products--item" v-if="order.discount">
                                                                    <th scope="row">
                                                                        <h4>Discount:</h4>
                                                                    </th>
                                                                    <td class="budget">
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       <h3>£{{order.discount}}</h3>
                                                                    </td>
                                                                </tr>
                                                                <tr class="order__products--item" v-if="order.collection_fee !== null">
                                                                    <th scope="row">
                                                                        <h4>Collection Fee:</h4>
                                                                    </th>
                                                                    <td class="budget">
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       <h3>{{order.collection_fee != 0 ? `£${order.collection_fee}` : 'Free'}}</h3>
                                                                    </td>
                                                                </tr>
                                                                <tr class="order__products--item" v-if="order.delivery_fee !== null">
                                                                    <th scope="row">
                                                                        <h4>Delivery Fee:</h4>
                                                                    </th>
                                                                    <td class="budget">
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       <h3>{{order.delivery_fee != 0 ? `£${order.delivery_fee}` : 'Free'}}</h3>
                                                                    </td>
                                                                </tr>
                                                                <tr class="order__products--item">
                                                                    <th scope="row">
                                                                        <h3>Total:</h3>
                                                                    </th>
                                                                    <td class="budget">
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                       <h2>£{{order.total}}</h2>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-4">
                                                <div class="row justify-content-between">
                                                    <div class="col-md-6">
                                                            <h2 class="">Information Details</h2>
                                                            <hr class="mt-3">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <h4><strong class="mr-2">Name:</strong> {{order.fullname}}</h4>
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <h4><strong class="mr-2">Address:</strong> {{order.address}}</h4>
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <h4><strong class="mr-2">postcode:</strong> {{order.postcode}}</h4>
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <h4><strong class="mr-2">Phone:</strong> {{order.phone}}</h4>
                                                                    </div>
                                                                     <div class="mb-3">
                                                                        <h4><strong class="mr-2">Email:</strong> {{order.email}}</h4>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <h4><strong class="mr-2">Order Description:</strong></h4>
                                                                        <p>{{order.note}}</p>
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <h4><strong class="mr-2">Instruction:</strong></h4>
                                                                        <p>{{order.instruction}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mt-5" v-if="order.collection_date && order.delivery_date">
                                                            <h2 class="">Expected Time</h2>
                                                            <hr class="mt-3">
                                                            <div class="">
                                                                <div class="mb-4">
                                                                    <div class="booking__content--collection">
                                                                        <h2>Collection Time</h2>
                                                                        <div class="mt-2">
                                                                            <h4>{{timeDate(order.collection_date)}}</h4>
                                                                            <div class="d-flex align-items-center">
                                                                                <h5 class="m-0">{{order.collection_time_from}}</h5>
                                                                                <div class="ml-2 mr-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <h5 class="m-0">{{order.collection_time_to}}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-4">
                                                                    <div class="booking__content--delivery">
                                                                        <h2>Delivery Time</h2>
                                                                        <div class="mt-2">
                                                                            <h4>{{timeDate(order.delivery_date)}}</h4>
                                                                            <div class="d-flex align-items-center">
                                                                                <h5 class="m-0">{{order.delivery_time_from}}</h5>
                                                                                <div class="ml-2 mr-2">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <h5 class="m-0">{{order.delivery_time_to}}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <form action="" @submit.prevent="updateOrder" method="post">
                                                            <div class="form-group">
                                                                <label for=""><small>Status</small></label>
                                                                <select ref="status" name="order_status" id="" class="form-control form-control-alternative">
                                                                    <option value="">Select Status</option>
                                                                    <option :selected="order.status =='pending' ? true : false" value="pending">Pending</option>
                                                                    <option :selected="order.status =='on-delivery' ? true : false" value="on-delivery">On Delivery</option>
                                                                    <option :selected="order.status =='complete' ? true : false" value="complete">Complete</option>
                                                                    <option :selected="order.status =='reject' ? true : false" value="reject">Reject</option>
                                                                    <option :selected="order.status =='refund' ? true : false" value="refund">Refund</option>
                                                                </select>
                                                            </div>
                                                           
                                                            <div class="form-group">
                                                                <button type="submit" class="btn btn-primary btn-block">Update Order <img v-if="loading" width="25" class="ml-1 mt--2" src="/img/loading.gif" /></button>
                                                            </div>
                                                            <!-- <p style="font-size: 13px"><strong>Note: </strong>When updating the order. It well automatically send an email to customer about the update of the order.</p> -->
                                                        </form>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </card>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'
import moment from "moment";
export default {
    props: ['order'],
    components: {
        DashboardLayout,

    },
    data() {
      return {   
          loading: false
      };
    },
    computed: {
        orders(){
            return this.$store.getters.orders;
        },
        order_detail(){
            return this.$store.getters.order;
        }
    },
    methods: {
        timeDate(date){
            return moment(date).format('dddd, MMMM Do YYYY');
        }, 
        orderDate(date) {
            return moment(date).format('ll');
        },
        updateOrder() {
            this.loading = true;
            const status = this.$refs.status.value;

            if (!status == '') {
                this.$store.dispatch('updateOrder', {
                        id: this.order.id,
                        data: {status},
                    })
                    .then(res => {
                        this.$notify({
                            title: 'Success',
                            message: `Order#: ${res.id} has been updated successfully`,
                            type: 'success'
                        });
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.$notify({
                    title: 'Error',
                    message: `Order status is required`,
                    type: 'error'
                });
            }
        },
    },
    mounted() {
       this.$store.dispatch('fetchAllOrders');
       this.$store.dispatch('fetchOrder', this.order.id)
    }
  };
</script>
<style></style>