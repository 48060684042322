<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Orders</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                              <div class="card">
                                    <div class="card-header border-0">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-4">
                                            <h3 class="mb-0">All Orders</h3>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group input-group">
                                                <input placeholder="Search here" v-model="keyword" class="form-control"> 
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" @click="searchOrder">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                    <template v-if="orders.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="orders">
                                            <template slot="columns">
                                                <th>Order Id</th>
                                                <th>Customer</th>
                                                <th>Status</th>
                                                <th>Discount</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" class="align-middle">
                                                    #{{row.id}}
                                                </th>
                                                <td class="align-middle">
                                                    {{row.fullname}}
                                                </td>
                                                <td class="align-middle">
                                                    <badge v-if="row.status == 'complete'" pill type="success">{{row.status}}</badge>
                                                    <badge v-else pill type="warning">{{row.status}}</badge>
                                                </td>
                                                <td class="align-middle">
                                                    {{row.discount ? row.discount : 'No'}}
                                                </td>
                                                <td class="align-middle">
                                                    £{{row.total}}
                                                </td>                                
                                                <td class="align-middle">
                                                    <inertia-link :href="`/admin/orders/${row.id}`" class="m-2">View</inertia-link>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-5 mb-5">
                                            <h4>No Orders</h4>
                                        </div>
                                    </template>
                                    <template v-if="orders.length >= 10">
                                        <div class="text-center mt-5 mb-5">   
                                            <button class="btn btn-primary" @click="showMoreOrders">Load More</button>
                                        </div>
                                    </template>

                                </div>
                            </div>
                        </div>
                
                </div>
            </div>

        </dashboard-layout>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'

  export default {
    components: {
        DashboardLayout,

    },
    data() {
      return {  
          orders: [],
          keyword: ''
      }
    },
    computed: {
        order_list(){
            return this.$store.getters.orders;
        }
    },
    methods: {
        showMoreOrders(){
            this.$store.dispatch('fetchAllOrders', {limit: this.orders.length + 10, keyword: this.keyword}).then(res => {
                this.orders = res;
            });
        },
        searchOrder(){
            this.$store.dispatch('fetchAllOrders', {limit: 10, keyword: this.keyword}).then(res => {
                this.orders = res;
            });
           
        }
    },
    mounted() { 
       this.$store.dispatch('fetchAllOrders', {limit: 10, keyword: this.keyword}).then(res => {
           this.orders = res;
       });
    }
  };
</script>
<style></style>
