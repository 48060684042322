<template>
    <div class="checkout">
        <layout :footer="false">
            <ValidationObserver v-slot="{invalid}">
            <form action="" method="post" @submit.prevent="onSubmit" class="mt-5">
                    <div class="container">
                        <div class="mb-4 d-flex align-items-center">
                            <div class="mr-3">
                                <inertia-link href="/booking">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                    </svg>
                                </inertia-link>
                            </div>
                            <el-breadcrumb separator="/" class="">
                                <el-breadcrumb-item><inertia-link href="/#orders">Orders</inertia-link></el-breadcrumb-item>
                                <el-breadcrumb-item><inertia-link href="/booking">Booking</inertia-link></el-breadcrumb-item>
                                <el-breadcrumb-item>Checkout</el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                        <div class="row">
                            <div class="col-md-7 col-lg-7 col-sm-12 mb-5">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <div class="checkout__header">
                                                <h2>CONTACT DETAILS</h2>
                                            </div>
                                            <div class="checkout__content">
                                                <div class="checkout__content--notify d-flex align-items-center">
                                                    <img src="/img/padlock.png" class="mr-4" alt="">
                                                    <p>Your card details are securely stored and we will never share your contact details with third parties.</p>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <ValidationProvider rules="required" v-slot="{errors}">
                                                            <label for="">
                                                                <span class="text-danger" v-if="errors[0]">Firstname {{ errors[0] }}</span>
                                                                <span v-else>Firstname
                                                                    <span class="text-danger">*</span>
                                                                </span>
                                                            </label>
                                                            <input type="text" v-model="model.firstname" class="form-control" placeholder="Your First Name">
                                                        </ValidationProvider>   
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <ValidationProvider rules="required" v-slot="{errors}">
                                                            <label for="">
                                                                <span class="text-danger" v-if="errors[0]">Lastname {{ errors[0] }}</span>
                                                                <span v-else>Lastname
                                                                    <span class="text-danger">*</span>
                                                                </span>
                                                            </label>
                                                            <input type="text" v-model="model.lastname" class="form-control" placeholder="Your Last Name">
                                                        </ValidationProvider>   
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <ValidationProvider rules="required" v-slot="{errors}">
                                                        <label for="">
                                                            <span class="text-danger" v-if="errors[0]">Mobile {{ errors[0] }}</span>
                                                            <span v-else>Mobile 
                                                                <span class="text-danger">*</span>
                                                            </span>
                                                        </label>
                                                        <vue-tel-input v-model="model.mobile"></vue-tel-input>
                                                    </ValidationProvider>   
                                                </div>
                                                <div class="form-group">
                                                    <ValidationProvider rules="required" v-slot="{errors}">
                                                        <label for="">
                                                            <span class="text-danger" v-if="errors[0]">Email {{ errors[0] }}</span>
                                                            <span v-else>Email
                                                                <span class="text-danger">*</span>
                                                            </span>
                                                        </label>
                                                        <input type="text" ref="email" @input="onChangeEmail" class="form-control" placeholder="Your Email" required>
                                                    </ValidationProvider>   
                                                </div>
                                                <div class="form-group" v-if="!isUser">
                                                    <ValidationProvider rules="required" v-slot="{errors}">
                                                        <label for="">
                                                            <span class="text-danger" v-if="errors[0]">Password {{ errors[0] }}</span>
                                                            <span v-else>Password
                                                                <span class="text-danger">*</span>
                                                            </span>
                                                        </label>
                                                        <input type="password" v-model="model.password" class="form-control" placeholder="Create your password">
                                                    </ValidationProvider>   
                                                </div>
                                                 <div class="form-group">
                                                     <label for="">Where did you hear about Laundrapp?</label>
                                                     <select name="" class="form-control" v-model="model.hear_us" id="">
                                                         <option value=""></option>
                                                         <option value="tv">TV</option>
                                                         <option value="cinema">Cinema</option>
                                                         <option value="search engine">Search Engine</option>
                                                         <option value="app store">App Store</option>
                                                         <option value="social media">Social Media</option>
                                                         <option value="outdoor/tube ad">Outdoor / Tube Ad</option>
                                                         <option value="word of mouth">Word Of Mouth</option>
                                                         <option value="other">Other</option>
                                                     </select>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 mb-5">
                                <div class="checkout__payment">
                                    <div class="card checkout__payment--dark">
                                        <div class="card-body">
                                            <div class="checkout__payment__header">
                                                <h2>PAYMENT DETAILS</h2>
                                            </div>

                                            <div class="checkout__payment__content">
                                                <base-alert type="danger" class="mb-4" dismissible v-if="error">
                                                    <span class="alert-inner--text text-white"><strong>Oops</strong> {{error}}</span>
                                                    <button type="button" class="close" @click="error = ''">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </base-alert>
                                               <div class="checkout__payment__content--stipe">
                                                    <div class="mb-3 d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h2 class="text-white mb-0"><small class="mr-1">Total:</small> £{{cartTotal()}}</h2>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <img class="m-1" src="/img/v-i-s-a.svg" alt="">
                                                            <img class="m-1" src="/img/m-a-s-t-e-r-c-a-r-d.svg" alt="">
                                                            <img class="m-1" src="/img/a-m-e-x.svg" alt="">
                                                        </div>
                                                    </div>
                                                    <div id="card-element"></div>
                                               </div>

                                                <div class="mt-4">
                                                    <p>We will reserve the final amount on your card once you have placed your order and only charge once we cleaned your items. <a href="">Payment FAQ.</a></p>
                                                    <div class="form-check mt-4">
                                                        <input class="form-check-input" type="checkbox" value="1" @click="checkedOffer = !checkedOffer" :checked="checkedOffer"  id="special_offer">
                                                        <label class="form-check-label ml-2" for="special_offer">I want to receive exclusive Laundrapp news & special offers</label>
                                                    </div>
                                                     <div class="form-check mt-3">
                                                        <input class="form-check-input" type="checkbox" @click="checkedPrivacy = !checkedPrivacy" value="1" :checked="checkedPrivacy" id="privacy_policy">
                                                        <label class="form-check-label ml-2" for="privacy_policy">I accept the Laundrapp Terms & Conditions & Laundrapp Privacy Policy Please accept the terms and conditions</label>
                                                    </div>
                                                </div>
                                                <div>
                                                     <button type="submit" id="card-button" class="btn btn-primary btn-block mt-4 d-flex justify-content-center align-items-center" :disabled="invalid">
                                                        <span v-if="!loading">Complete Booking</span>
                                                        <img v-else src="/img/loading.gif" alt="" width="30">
                                                    </button>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </form>
            </ValidationObserver>
             <footer-component></footer-component>
        </layout>
    </div>
</template>

<script>
import LayoutVue from "./../Layouts/Layout.vue";
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {loadStripe} from '@stripe/stripe-js';
import FooterVue from '../Layouts/Footer.vue';

extend('required', {
  ...required,
  message: 'field is required'
});
export default {
    components: {
        layout:LayoutVue,
        ValidationProvider,
        ValidationObserver,
        FooterComponent: FooterVue
    },
    data(){
        return {
            model: {
                firstname: this.$page.user ? this.$page.user.name : '',
                lastname: this.$page.user ? this.$page.user.lname : '',
                email: this.$page.user ? this.$page.user.email : '',
                mobile: this.$page.user ? this.$page.user.mobile : '',
                password: '',
                hear_us: '',
            },
            cardHolder: '',
            checkedPrivacy: false,
            checkedOffer: false,
            error: "",
            discount_amount: 0,
            discount_type: '',
            loading: false,
            isUser: this.$page.user ? true : false,
            delivery_fee: 0,
            collection_fee: 0
        }
    },
    computed: {
        carts(){
            return this.$store.getters.carts;
        }
    },
    methods: {
        onChangeEmail(){
            console.log(this.$refs.email.value)
            this.model.email = this.$refs.email.value;
            window.axios.post('/api/isEmail', {email: this.$refs.email.value, _token: window.token}).then(res => {
                if (res.data.status == 'success') {
                    this.isUser = true;
                } else {
                    this.isUser = false;
                }
            })
        },
         cartTotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })

            total = total > 20 ? total : 20;

            if (this.collection_fee) {
                total = parseFloat(total) + parseFloat(this.collection_fee)
            }

            if (this.delivery_fee) {
                total = parseFloat(total) + parseFloat(this.delivery_fee)
            }
            console.log('total', total);
            if (this.discount_type) {
                if (this.discount_type == 'fixedamount') {
                    total = total - this.discount_amount
                } else {
                    let subtotal = (total / 100) * this.discount_amount
                    total = total - subtotal
                }
                 return parseFloat(total).toFixed(2);
            }

            return parseFloat(total).toFixed(2);
        },
        cartSubtotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })
            return total > 20 ?  parseFloat(total).toFixed(2) : parseFloat(20).toFixed(2);
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        onSubmit(){

        },

    },
    async mounted(){
        window.axios.get('/api/get-voucher').then(res => {
             if (res.data) {
                 this.discount_amount = res.data.amount;
                 this.discount_type = res.data.type
             }
        })

         window.axios.get('/api/booking').then(res => {
             console.log('fetchBooking', res);
            this.collection_fee = res.data.collection_fee
            this.delivery_fee = res.data.delivery_fee
        });

        this.$store.dispatch('fetchCart');

        const stripe = await loadStripe('pk_test_AB2RavcO4C06YAnX2lddgQpU00trAfr2sb');
        const elements = stripe.elements();
        const cardElement = elements.create('card', {
                style: {
                   base: {
                        padding: '10px 12px',
                        iconColor: '#fff',
                        color: '#fff',
                        fontWeight: 500,
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                            color: '#aab7c4'
                        },
                    },
                    invalid: {
                        color: '#fa755a',
                    }
                },
            });

        cardElement.mount('#card-element');
        const cardButton = document.getElementById('card-button');
        cardButton.addEventListener('click', async (e) => {
            
            const { paymentMethod, error } = await stripe.createPaymentMethod('card', cardElement);
            
            if (error) {
                console.log(error);
                this.error = error.message
            } else if (!this.validateEmail(this.model.email)) {
                console.log(this.validateEmail(this.model.email));
                this.error = 'Email is Invalid'
            } else {
                this.loading = true;

                if (!this.checkedPrivacy) {
                    this.error = "Please accept the terms and condition";
                }else {
                    const data = {
                        paymentMethod,
                        discount: this.discount_amount,
                        checked_privacy: this.checkedPrivacy,
                        checked_offer: this.checkedOffer,
                        ...this.model,
                        total: this.cartTotal(),
                        subtotal: this.cartSubtotal(),
                        _token: window.token
                    }
                    window.axios.post('/api/booking-complete', data).then((res) => {
                        this.loading = false;
                        if (res.data.status == 'error') {
                            this.error = res.data.message;
                        } else {
                            window.location.href = '/booking-success';
                        }
                        
                    }).catch(err => {
                        console.log(err)
                    })
                }
             
            }
        });
    }
}
</script>