<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item><inertia-link href="/admin/customers">customers</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Customer Detail</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                              <div class="card">
                                    <div class="card-header border-0">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h3 class="mb-0">Customer: {{customer.name}}</h3>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div class="p-4">
                                        <form @submit.prevent="onSubmit">
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="">Firtname</label>
                                                    <input type="text" class="form-control" v-model="model.firstname"  name="" value="">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="">Lastname</label>
                                                    <input type="text" class="form-control" v-model="model.lastname"  name="" value="">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="">Address</label>
                                                    <input type="text" class="form-control" v-model="model.address"  name="" value="">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="">Mobile</label>
                                                    <input type="text" class="form-control" v-model="model.mobile"  name="" value="">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">Postcode</label>
                                                <input type="text" class="form-control" v-model="model.postcode"  name="" value="">
                                            </div>
                                             <div class="form-group">
                                                <label for="">Email</label>
                                                <input type="text" class="form-control" disabled v-model="model.email"  name="" value="">
                                            </div>
                                             <div class="form-group">
                                               <button type="submit" class="btn btn-primary">Save Customer</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'

export default {
    props: ['customer'],
    components: {
        DashboardLayout,

    },
    data() {
      return {   
          model: {
              firstname: this.customer.name,
              lastname: this.customer.lname,
              address: this.customer.address,
              postcode: this.customer.postcode,
              mobile: this.customer.mobile,
              email: this.customer.email
          }
      };
    },
    computed: {
        
    },
    methods: {
      onSubmit(){
          window.axios.post(`/update-account/${this.customer.id}`, {_token: window.token, ...this.model}).then(res => {
               if (res.data.status == 'success') {
                     this.$notify({
                        title: 'Success',
                        message: 'Customer has been successfully updated',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        title: 'Error',
                        message: 'Something went wrong please try again',
                        type: 'error'
                    });
                }
          })
      }
    },
    mounted() {
      
    }
  };
</script>
<style></style>
