<template>
    <div>
        <el-dialog title="Add Booking Schedules" :visible.sync="$parent.dialogCreateVisible">
            <form @submit.prevent="onSubmit">
                <div>
                    <div class="form-group">
                        <label for="">Postcodes</label>
                        <input-tag v-model="model.postcodes"></input-tag>
                    </div>
                    <div class="form-group">
                        <label for="">Default Delivery Time Interval</label>
                        <input type="number" name="" v-model="model.default_delivery" class="form-control" value="">
                    </div>
                </div>  
                <span slot="footer" class="dialog-footer">
                    <button type="submit" class="btn btn-primary">Add</button>
                </span>
            </form>
        </el-dialog>
    </div>
</template>

<script>
import InputTag from 'vue-input-tag'
export default {
    components: {
        InputTag
    },
    data(){
        return {
            model: {
                postcodes: [],
                default_delivery: '',
            },
            error: ''
        }
    },
    methods: {
        onSubmit(){
            console.log(this.model.postcodes);
            const postcodes = this.model.postcodes;
            if (postcodes.length > 0) {
                let data = {
                    postcodes: JSON.stringify(this.model.postcodes),
                    default_delivery: this.model.default_delivery
                }

                window.axios.post('/api/admin/add-booking-postcode', data).then(res => {
                    console.log(res)
                    if (res.data.status == 'error') {
                        this.$notify({
                            title: 'Oops',
                            message: res.data.message,
                            type: 'error'
                        });
                    } else {
                        this.$parent.addBooking(res.data.booking);
                        
                        this.$notify({
                            title: 'Success',
                            message: `Successfully added`,
                            type: 'success'
                        });

                        this.model = {
                            postcodes: [],
                            default_delivery: '',
                        }

                        this.$parent.dialogCreateVisible = false;
                    }
                })
            } else {
                 this.$notify({
                    title: 'Error',
                    message: `Postcode is empty`,
                    type: 'error'
                });
            }
            
        }
    },
    mounted() {

    }
}
</script>