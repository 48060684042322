const state = {
    carts: []
};

const mutations = {
    ADD_CART(state, payload){
        let cartIndex = window._.findIndex(state.carts, {id: payload.id});
        if (cartIndex != -1) {
            state.carts.splice(cartIndex, 1, payload);
        }else {
            state.carts = [payload, ...state.carts]
        }
      
        
    },
    REMOVE_CART(state, payload){
        let cartIndex = window._.findIndex(state.carts, {id: payload.id});
        if (payload.qty > 0) {
            state.carts.splice(cartIndex, 1, payload);
        } else {
            state.carts = state.carts.filter((cart) => cart.id != payload.id)
        }
        
    },

    FETCH_CART(state, payload){
        state.carts = payload
    }
};

const actions = {
    addCart({commit, state}, payload){
        commit('ADD_CART', payload);
        window.axios.post('/api/cart', {cart: state.carts, _token: window.token}).then((res) => {
            console.log(res)
        })

    },
    removeCart({commit, state}, payload){
        commit('REMOVE_CART', payload);
        console.log(state.carts);
        window.axios.post('/api/cart', {cart: state.carts, _token: window.token}).then((res) => {
            console.log(res)
        })
    },
    fetchCart({commit}, payload) {
        window.axios.get('/api/cart').then((res) => {
            console.log(res.data)
            commit('FETCH_CART', res.data);
        })
    },
};

const getters = {
    carts(state){
        return state.carts
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}