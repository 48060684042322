<template>
    <div class="booking-success">
        <layout>
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-12 col-lg-12">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <img class="booking-success__img" src="/img/order_confirmed.svg" alt="">
                            <div class="mt-4">
                                <h2>Thank you for booking</h2>
                            </div>
                            <div class="mt-4">
                                <inertia-link href="/orders" class="btn btn-primary">Go to orders</inertia-link>
                                <inertia-link href="/" class="btn btn-secondary">Go to Home</inertia-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer-component></footer-component>
        </layout>
    </div>
</template>

<script>
import FooterVue from '../Layouts/Footer.vue';
import LayoutVue from "./../Layouts/Layout.vue";
export default {
    components: {
        Layout: LayoutVue,
        FooterComponent: FooterVue
    }
}
</script>