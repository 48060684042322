

const state  = {
    orders: [],
    order: null,
}

const mutations = {
    ADD_ORDERS(state, payload){
        state.orders = [payload, ...state.orders]
    },
    EDIT_ORDER(state, payload){
        state.orders = state.orders.map((order) => order.id == payload.id ? payload : order)
    },
    DELETE_ORDER(state, payload){
        state.orders = state.orders.filter((order) => order.id != payload.id)
    },
    ORDERS(state, payload){
        state.orders = payload;
    },
    ORDER(state, payload){
        state.order = payload;
    }
}

const actions = {
    fetchAllOrders({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/fetch-orders`, payload)
            .then((res) => {
                commit('ORDERS', res.data);
                resolve(res.data)
            }).catch((err) => {
                console.log(err.response.data.message);
                reject(err.response.data.message);
            });
        
        });
          
    },

    fetchOrder({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/fetch-order/${id}`, {id})
            .then((res) => {
                commit('ORDER', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    addOrder({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/add-order`, payload)
            .then((res) => {
                commit('ADD_ORDER', res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log('add error', err);
                reject(err);
            });
        });
    },

    updateOrder({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.patch(`/api/admin/update-order/${payload.id}`, payload.data)
            .then((res) => {
                commit('EDIT_ORDER', res.data);
                resolve(res.data);

            }).catch((err) => {
                console.log('update error', err.response.data.errors);
                reject(err.response.data.errors);
            });
        });
    },
    editOrder({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/orders/${id}/edit`)
            .then((res) => {
                commit('ORDER', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    deleteOrder({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.delete(`/api/admin/delete-order/${payload.id}`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                commit('DELETE_ORDER', res.data);
                resolve(res.data);
                // router.push('/admin/products');
            }).catch((err) => {
                console.log('add error', err.message);
                reject(err);
            });
        });
    }

}

const getters = {
    orders(state){
        return state.orders;
    },
    order(state){
        return state.order;
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}