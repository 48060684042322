<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Voucher</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-5">
                                <div class="row justify-content-end">
                                    <div class="col-md-5">
                                        <div class="form-group input-group">
                                            <input placeholder="Search here" v-model="keyword" class="form-control"> 
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" @click="searchVoucher">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                              <div class="card">
                                    <div class="card-header border-0">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h3 class="mb-0">All Voucher</h3>
                                        </div>
                                        <div class="col text-right">
                                            <inertia-link href="/admin/vouchers/create" class="btn btn-primary">Add Voucher</inertia-link>
                                        </div>
                                    </div>
                                    </div>

                                    <template v-if="vouchers.length > 0">
                                        <div class="table-responsive">
                                            <base-table thead-classes="thead-light"
                                                        :data="vouchers">
                                            <template slot="columns">
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Is Forever</th>
                                                <th>Type</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Action</th>
                                            </template>

                                            <template slot-scope="{row}">
                                                <th scope="row" class="align-middle">
                                                    {{row.name}}
                                                </th>
                                                <td class="align-middle">
                                                    {{row.amount}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.is_forever ? 'Yes' : 'No'}}
                                                </td>
                                                 <td class="align-middle">
                                                    {{row.type}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.from ? date(row.from) : '-'}}
                                                </td>
                                                <td class="align-middle">
                                                    {{row.to ? date(row.to) : '-'}}
                                                </td>
                                                <td class="align-middle">
                                                    <inertia-link :href="`/admin/vouchers/${row.id}/edit/`" class="m-2"><i class="el-icon-edit admin-icon text-info"></i></inertia-link>
                                                    <a @click.prevent="dialogDelete(true, row.id)" :href="`/admin/products/delete/${row.id}`" class="m-2"><i class="el-icon-delete admin-icon"></i></a>
                                                </td>
                                            </template>

                                            </base-table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="text-center mt-5 mb-5">
                                            <h4>No Voucher</h4>
                                        </div>
                                    </template>
                                    <template v-if="vouchers.length >= 10">
                                        <div class="text-center mt-5 mb-5">
                                            <button class="btn btn-primary" @click="seeMoreVoucher">Load More</button>
                                        </div>
                                    </template>

                                </div>
                        </div>

                    </div>

                
                </div>
            </div>
        </dashboard-layout>
         <delete-voucher v-if="dialogDeleteId" :key="dialogDeleteId"></delete-voucher>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'
import DeleteVoucher from './Delete';
import moment from 'moment'
export default {
    components: {
        DashboardLayout,
        DeleteVoucher
    },
    data() {
      return {
        data: [],
        dialogDeleteVisible: false,
        dialogDeleteId: 0,
        keyword: ''
      };
    },
    computed: {
      vouchers(){
          return this.$store.getters.vouchers;
      }
    },
    methods: {
        dialogDelete(status, id){
            console.log(status, id);
            this.dialogDeleteId = id;
            this.dialogDeleteVisible = status;
        },
        date(date){
            return moment(date).format('MM/DD/YYYY');
        },

        seeMoreVoucher(){
             this.$store.dispatch('fetchAllVouchers', {limit: this.vouchers.length + 10, keyword: this.keyword});
        },
        searchVoucher(){
             this.$store.dispatch('fetchAllVouchers', {limit: 10, keyword: this.keyword});
        }
    },
    mounted() {
       this.$store.dispatch('fetchAllVouchers', {limit: 10, keyword: this.keyword});
    }
  };
</script>
<style></style>
