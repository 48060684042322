<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
            <div class="container-fluid mt-4">
                <el-breadcrumb separator="/" class="mt-3 ml-3">
                    <el-breadcrumb-item><inertia-link href="/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                </el-breadcrumb>
                <div class="row">
                    <div class="col-xl-8 mb-5 mb-xl-0">
                        <div class="card mt-5">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">Recent Orders</h3>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <template v-if="order_limits.length > 0">
                                    <div class="table-responsive">
                                        <base-table thead-classes="thead-light"
                                                    :data="orders">
                                        <template slot="columns">
                                            <th>Order Id</th>
                                            <th>Status</th>
                                            <th>Discount</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </template>

                                        <template slot-scope="{row}">
                                            <th scope="row" class="align-middle">
                                                #{{row.id}}
                                            </th>
                                         
                                            <td class="align-middle">
                                                <badge v-if="row.status == 'complete'" pill type="success">{{row.status}}</badge>
                                                <badge v-else pill type="warning">{{row.status}}</badge>
                                            </td>
                                            <td class="align-middle">
                                                {{row.discount ? row.discount : 'No'}}
                                            </td>
                                            <td class="align-middle">
                                                £{{row.total}}
                                            </td>                                
                                            <td class="align-middle">
                                                <inertia-link :href="`/order/${row.id}`" class="m-2">View</inertia-link>
                                            </td>
                                        </template>

                                        </base-table>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center mt-5 mb-5">
                                        <h4>No Orders</h4>
                                    </div>
                                </template>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4">
                        <div class="card mt-5">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0"></h3>
                                    </div>
                                    
                                </div>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End charts-->

                <!--Tables-->
                <div class="row mt-5">
                
                </div>
                <!--End tables-->
            </div>
            </div>

        </dashboard-layout>

    </div>
</template>
<script>

import DashboardLayout from '../../layout/DashboardLayout'
export default {
    props: ['orders', 'order_limits'],
    components: {
        DashboardLayout
    },
    data() {
      return {
    
      };
    },
    methods: {

    },
    mounted() {
    
    }
  };
</script>
<style></style>
