<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
            <div class="container-fluid mt-5">
                <el-breadcrumb separator="/" class="mt-3 ml-3">
                    <el-breadcrumb-item><inertia-link href="/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                    <el-breadcrumb-item>Account</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="row">
                    <div class="col-xl-8 mb-5 mb-xl-0 mt-5">
                          <card shadow type="secondary">
                            <div slot="header">
                                <h3 class="m-0">My Account</h3>
                            </div>
                            <div>
                                 <ValidationObserver v-slot="{invalid}">
                                <form @submit.prevent="onSubmit">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <ValidationProvider rules="required" v-slot="{errors}">
                                                <label for="">
                                                    <span class="text-danger" v-if="errors[0]">Firstname {{ errors[0] }}</span>
                                                    <span v-else>Firstname
                                                        <span class="text-danger">*</span>
                                                    </span>
                                                </label>
                                                <input type="text" v-model="model.firstname" class="form-control" placeholder="Your First Name">
                                            </ValidationProvider>   
                                        </div>
                                        <div class="form-group col-md-6">
                                            <ValidationProvider rules="required" v-slot="{errors}">
                                                <label for="">
                                                    <span class="text-danger" v-if="errors[0]">Lastname {{ errors[0] }}</span>
                                                    <span v-else>Lastname
                                                        <span class="text-danger">*</span>
                                                    </span>
                                                </label>
                                                <input type="text" v-model="model.lastname" class="form-control" placeholder="Your Last Name">
                                            </ValidationProvider>   
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <ValidationProvider rules="required" v-slot="{errors}">
                                            <label for="">
                                                <span class="text-danger" v-if="errors[0]">Mobile {{ errors[0] }}</span>
                                                <span v-else>Mobile 
                                                    <span class="text-danger">*</span>
                                                </span>
                                            </label>
                                            <vue-tel-input v-model="model.mobile"></vue-tel-input>
                                        </ValidationProvider>   
                                    </div>
                                    <div class="form-group">
                                        <ValidationProvider rules="required" v-slot="{errors}">
                                            <label for="">
                                                <span class="text-danger" v-if="errors[0]">Postcode {{ errors[0] }}</span>
                                                <span v-else>Postcode
                                                    <span class="text-danger">*</span>
                                                </span>
                                            </label>
                                            <input type="text" v-model="model.postcode" class="form-control" placeholder="Your Postcode">
                                        </ValidationProvider>   
                                    </div>
                                      <div class="form-group">
                                        <ValidationProvider rules="required" v-slot="{errors}">
                                            <label for="">
                                                <span class="text-danger" v-if="errors[0]">Address {{ errors[0] }}</span>
                                                <span v-else>Address
                                                    <span class="text-danger">*</span>
                                                </span>
                                            </label>
                                            <input type="text" v-model="model.address" class="form-control" placeholder="Your Address">
                                        </ValidationProvider>   
                                    </div>
                                    <div class="form-group">
                                        <ValidationProvider rules="required" v-slot="{errors}">
                                            <label for="">
                                                <span class="text-danger" v-if="errors[0]">Email {{ errors[0] }}</span>
                                                <span v-else>Email
                                                    <span class="text-danger">*</span>
                                                </span>
                                            </label>
                                            <input type="text" v-model="model.email" class="form-control" placeholder="Your Email">
                                        </ValidationProvider>   
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" :disabled="invalid" class="btn btn-primary">Save Account</button>
                                    </div>
                                </form>
                                 </ValidationObserver>
                            </div>
                          </card>
                    </div>

                    <div class="col-xl-4">
                      
                    </div>
                </div>
                <!-- End charts-->

                <!--Tables-->
                <div class="row mt-5">
                
                </div>
                <!--End tables-->
            </div>
            </div>

        </dashboard-layout>

    </div>
</template>
<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import DashboardLayout from '../../layout/DashboardLayout';
extend('required', {
  ...required,
  message: 'field is required'
});
export default {
    components: {
        DashboardLayout,
        ValidationProvider,
        ValidationObserver
    },
    data() {
      return {
          model: {
              firstname: this.$page.user ? this.$page.user.name : '',
              lastname: this.$page.user ? this.$page.user.lname : '',
              email: this.$page.user ? this.$page.user.email : '',
              postcode: this.$page.user ? this.$page.user.postcode : '',
              mobile: this.$page.user ? this.$page.user.mobile : '',
              address: this.$page.user ? this.$page.user.address : '',
          }
      };
    },
    methods: {
        onSubmit(){
            window.axios.post(`/api/update-account`, {user_id: this.$page.user.id, ...this.model, }).then(res => {
                if (res.data.status == 'success') {
                     this.$notify({
                        title: 'Success',
                        message: 'Your account has been successfully updated',
                        type: 'success'
                    });
                } else {
                    this.$notify({
                        title: 'Error',
                        message: 'Something went wrong please try again',
                        type: 'error'
                    });
                }
               
            })
            .catch(err => console.log(err))
        }
    },
    mounted() {
    
    }
  };
</script>
<style></style>