<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <!-- Card stats -->



            <!--Charts-->
            <div class="container-fluid mt-5">
                <div class="row">
                    <div class="col-xl-8 mb-5 mb-xl-0">

                    </div>

                    <div class="col-xl-4">
                      
                    </div>
                </div>
                <!-- End charts-->

                <!--Tables-->
                <div class="row mt-5">
                
                </div>
                <!--End tables-->
            </div>
            </div>

        </dashboard-layout>

    </div>
</template>
<script>

import DashboardLayout from '../layout/DashboardLayout'

export default {
    props: ['order_count', 'product_count', 'customer_count', 'orders'],
    components: {
        DashboardLayout
    },
    data() {
      return {
    
      };
    },
    methods: {

    },
    mounted() {
    
    }
  };
</script>
<style></style>
