<template>
    <div>
        <!-- fixed-top  -->
        <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-white overflow-hidden">
            <inertia-link class="navbar-brand" href="/">Cleanafi</inertia-link>
            <div class="d-flex align-items-center">
                <div class="mr-3">
                    <div class="cart cart-mobile" @click="drawer = true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="currentColor" class="bi bi-basket" viewBox="0 0 16 16">
                            <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                        <span class="cart__count">{{cartCount()}}</span>
                    </div>
                </div>
                <button class="navbar-toggler" @click="navbar = true" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav mr-auto">
                    
                </ul>
                <ul class="navbar-nav align-items-center">
                    <li :class="`nav-item py-3 ${selected == 'home' ? 'active' : ''}`" @click="selected='home'">
                        <a class="nav-link" href="/">Home</a>
                    </li>
                    <li :class="`nav-item py-3 ${selected == 'how-it-work' ? 'active' : ''}`" @click="selected='how-it-work'">
                        <a class="nav-link" href="/#how-it-work">How does it work?</a>
                    </li>
                    <li :class="`nav-item py-3 ${selected == 'services' ? 'active' : ''}`" @click="selected='services'">
                        <a class="nav-link" href="/#services">Services</a>
                    </li>
                     <li :class="`nav-item py-3 ${selected == 'contact' ? 'active' : ''}`" @click="selected='contact'">
                        <a class="nav-link" href="/#contact-us">Contact Us</a>
                    </li>
                    <li :class="`nav-item py-3 ${selected == 'faq' ? 'active' : ''}`" @click="selected='faq'">
                        <a class="nav-link" href="/#faq">FAQs</a>
                    </li>
                    <li class="mx-3">
                        <div class="cart" @click="drawer = true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="currentColor" class="bi bi-basket" viewBox="0 0 16 16">
                                <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                            <span class="cart__count">{{cartCount()}}</span>
                        </div>
                    </li>
                    <li class="nav-item py-3" v-if="$page.user">
                        <inertia-link class="nav-link d-flex align-items-center" href="/dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                            </svg>
                            <span class="ml-2">Account</span>
                        </inertia-link>
                    </li>
                    <li class="nav-item py-3" v-else>
                        <a class="nav-link btn btn-primary ml-4 text-white" href="/login">Sign In</a>
                    </li>
                </ul>
            </div>
        </nav>

        <el-drawer
            title="Navbar"
            :visible.sync="navbar"
            :show-close="true"
            :with-header="false">
            <div class="mobile-nav">
                <div class="mobile-nav__list">
                    <a class="mobile-nav__list--item" href="/">Home</a>
                    <a class="mobile-nav__list--item" href="/#how-it-work">How does it work?</a>
                    <a class="mobile-nav__list--item" href="/#services">Services</a>
                    <a class="mobile-nav__list--item" href="/#contact-us">Contact Us</a>
                    <a class="mobile-nav__list--item" href="/#faq">FAQs</a>
                    <inertia-link v-if="$page.user" class="mobile-nav__list--item d-flex align-items-center" href="/dashboard">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                        </svg>
                        <span class="ml-2">Account</span>
                    </inertia-link>
                
                    <div class="px-3" v-else>
                        <a class="btn btn-primary btn-block mt-4 text-white" href="/login">Sign In</a>
                    </div>
                </div>
            </div>
        </el-drawer>

        <el-drawer
            title="Orders"
            :show-close="true"
            :visible.sync="drawer"
            :with-header="false">
                <div class="booking__content mt-4">
                <div class="px-4">
                    <h2>Your Basket</h2>
                    <hr class="mt-2 mb-4">
                </div>
                <div class="booking__content--carts px-4" v-if="carts.length > 0">
                    <div v-for="(cart, i) in carts" :key="i"  class="booking__content--carts__item d-flex justify-content-between align-content-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <img class="mr-3" :src="cart.image" alt="">
                            <span class="mr-3">x{{cart.qty}}</span>
                            <h5 class="mr-3">{{cart.title}}</h5>
                        </div>
                        <div class="d-flex justify-content-end align-items-center">
                            <h2 class="mr-3 mb-0">£{{cart.price}}</h2>
                            <span class="booking__content--carts__item--icon" @click="removeCart(cart);">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="booking__content--total mt-4 px-4">
                    <div class="booking__content--total__item d-flex justify-content-between align-items-center">
                        <div>
                            <h3>Subtotal</h3>
                            <p v-if="cartTotal() < 20">£20.00 minimum order</p>
                        </div>
                        <div>
                            <h3>£{{cartSubtotal()}}</h3>
                        </div>
                    </div>
                    <div v-if="discount_type" class="booking__content--total__item d-flex justify-content-between align-items-center">
                        <div>
                            <h3 class="text-success">Discount</h3>
                            
                        </div>
                        <div>
                            <h3 class="text-success">-{{discount_type == 'fixedamount' ? parseFloat(discount_amount).toFixed(2) : `${discount_amount}%`}}</h3>
                        </div>
                    </div>
                    <div v-if="bookingData && bookingData.selected_collection" class="booking__content--total__item d-flex justify-content-between align-items-center">
                        <div>
                            <h3>Collection Fee</h3>
                            
                        </div>
                        <div>
                            <h3 class="">{{bookingData.selected_collection.time.price ? `£${bookingData.selected_collection.time.price}` : 'Free'}}</h3>
                        </div>
                    </div>
                    <div v-if="bookingData && bookingData.selected_delivery" class="booking__content--total__item d-flex justify-content-between align-items-center">
                        <div>
                            <h3>Delivery Fee</h3>
                            
                        </div>
                        <div>
                            <h3 class="">{{bookingData.selected_delivery.time.price ? `£${bookingData.selected_delivery.time.price}` : 'Free'}}</h3>
                        </div>
                    </div>
                    <div class="booking__content--total__item d-flex justify-content-between align-items-center">
                        <div>
                            <h1>Total</h1>
                        </div>
                        <div>
                            <h1>£{{cartTotal()}}</h1>
                        </div>
                    </div>
                    <inertia-link class="mt-4 btn btn-primary btn-block d-flex justify-content-center align-items-center" href="/booking">
                        <span> {{carts.length > 0 ? 'Procced Booking' : 'Skip Item Selection'}}</span>
                        <span class="ml-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </span> 
                    </inertia-link>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    props: [],
    data(){
        return {
           drawer: false,
           total: 0,
           discount_amount: 0,
           discount_type: '',
           bookingData: null,
           voucher: '',
           selected: 'home',
           navbar: false
        }
    },
    computed: {
        carts(){
            return this.$store.getters.carts;
        }
    },
    methods: {
        cartSubtotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })
            return total > 20 ?  parseFloat(total).toFixed(2) : parseFloat(20).toFixed(2);
        },

        cartCount(){
            let count = 0;
             this.carts.map(cart => {
                count += cart.qty;
            })

            return count;
        },

        cartTotal(){
            let total = 0;
            this.carts.map(cart => {
                total += cart.qty * cart.price;
            })

            total = total > 20 ? total : 20;

            if (this.bookingData) {
              
                if (this.bookingData.selected_delivery.time.price) {
                    total = parseFloat(total) + parseFloat(this.bookingData.selected_delivery.time.price);
                }
                if(this.bookingData.selected_collection.time.price){
                    total = parseFloat(total) + parseFloat(this.bookingData.selected_collection.time.price);
                }
            }

            if (this.discount_type) {
                if (this.discount_type == 'fixedamount') {
                    total = total - this.discount_amount
                } else {
                    let subtotal = (total / 100) * this.discount_amount
                    total = total - subtotal
                }

                return parseFloat(total).toFixed(2);
            }

            return parseFloat(total).toFixed(2);
        },
        removeCart(cart){
            cart.qty--
            this.$store.dispatch('removeCart', cart)
        },
    },
    mounted(){
        window.$(window).scroll(function() {
            if(window.$(window).scrollTop() > 50) {
                window.$("#navbar").addClass("fixed-top");
                
            } else {
                window.$("#navbar").removeClass("fixed-top");
            }
        });

        window.axios.get('/api/get-voucher').then(res => {
             if (res.data) {
                 this.discount_amount = res.data.amount;
                 this.discount_type = res.data.type
             }
        })

        this.$store.dispatch('fetchCart');
    }
}
</script>