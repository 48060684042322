<template>
    <div>
        <dashboard-layout>
            <div class="pt-7 px-4">
                <div class="container-fluid">
                   <el-breadcrumb separator="/" class="mt-3 ml-3">
                        <el-breadcrumb-item><inertia-link href="/admin/dashboard">Dashboard</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item><inertia-link href="/admin/vouchers">Vouchers</inertia-link></el-breadcrumb-item>
                        <el-breadcrumb-item>Update Voucher</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="row mt-4">
                        <div class="col-xl-8 col-lg-8 col-md-12 mb-5">
                            <div class="card">
                                <div class="card-body">
                                    <ValidationObserver v-slot="{invalid}">
                                    <form @submit.prevent="onSubmit">
                                        <div class="form-group">
                                             <ValidationProvider rules="required" v-slot="{errors}">
                                                <label for="">
                                                    <span class="text-danger" v-if="errors[0]">Voucher {{ errors[0] }}</span>
                                                    <span v-else>Voucher
                                                        <span class="text-danger">*</span>
                                                    </span>
                                                </label>
                                                <input type="text" v-model="model.name" class="form-control" placeholder="Voucher Name">
                                            </ValidationProvider>   
                                        </div>
                                        <div class="form-group">
                                             <ValidationProvider rules="required" v-slot="{errors}">
                                                <label for="">
                                                    <span class="text-danger" v-if="errors[0]">Type {{ errors[0] }}</span>
                                                    <span v-else>Voucher Type
                                                        <span class="text-danger">*</span>
                                                    </span>
                                                </label>
                                                <select v-model="model.type" class="form-control">
                                                    <option value="fixedamount">Fixed Amount</option>
                                                    <option value="percentage">Percentage</option>
                                                </select>
                                             
                                            </ValidationProvider>   
                                        </div>
                                        <div class="form-group">
                                             <ValidationProvider rules="required" v-slot="{errors}">
                                                <label for="">
                                                    <span class="text-danger" v-if="errors[0]">Voucher Amount {{ errors[0] }}</span>
                                                    <span v-else>Amount
                                                        <span class="text-danger">*</span>
                                                    </span>
                                                </label>
                                                
                                                <input type="text" v-model="model.amount" class="form-control" placeholder="Voucher Amount">
                                            </ValidationProvider>   
                                        </div>
                                        <div class="form-group form-check"> 
                                            <input class="form-check-input" type="checkbox" value="1" @click="model.is_forever = !model.is_forever" :checked="model.is_forever" id="is_forever">
                                            <label class="form-check-label" for="is_forever">
                                                Is Forever
                                            </label>
                                        </div>
                                        <div class="row" v-if="!model.is_forever">
                                            <div class="form-group col-md-6">
                                                <ValidationProvider rules="required" v-slot="{errors}">
                                                    <label for="">
                                                        <span class="text-danger" v-if="errors[0]">From {{ errors[0] }}</span>
                                                        <span v-else>From
                                                            <span class="text-danger">*</span>
                                                        </span>
                                                    </label>
                                                    
                                                    <input type="date" v-model="model.from" class="form-control" placeholder="From">
                                                </ValidationProvider>   
                                            </div>
                                            <div class="form-group col-md-6">
                                                <ValidationProvider rules="required" v-slot="{errors}">
                                                    <label for="">
                                                        <span class="text-danger" v-if="errors[0]">To {{ errors[0] }}</span>
                                                        <span v-else>To
                                                            <span class="text-danger">*</span>
                                                        </span>
                                                    </label>
                                                    
                                                    <input type="date" v-model="model.to" class="form-control" placeholder="To">
                                                </ValidationProvider>   
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" :disabled="invalid" class="btn btn-primary">Save Voucher</button>
                                        </div>
                                    </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>

                    </div>

                
                </div>
            </div>

        </dashboard-layout>
    </div>
</template>
<script>

import DashboardLayout from '../../../layout/DashboardLayout'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment'
extend('required', {
  ...required,
  message: 'field is required'
});
export default {
    props: ['voucher'],
    components: {
        DashboardLayout,
        ValidationProvider,
        ValidationObserver
    },
    data() {
      return {
        model: {
            name: '',
            type: '',
            is_forever: false,
            amount: '',
            from: '',
            to: '',
        }
       
      };
    },
    computed: {
      
    },
    methods: {
        onSubmit(){
          this.$store.dispatch('updateVoucher', {id: this.voucher.id, data: this.model}).then(res => {
            this.$notify({
                title: 'Success',
                message: `Voucher has been successfully update`,
                type: 'success'
            });
           
          });
        },
        date(date){
            return moment(date).format('YYYY-MM-DD');
        }
    },
    mounted() {
       this.model = {
            name: this.voucher.name,
            type: this.voucher.type,
            is_forever: this.voucher.is_forever,
            amount: this.voucher.amount,
            from: this.date(this.voucher.from),
            to: this.date(this.voucher.to),
       }
    }
  };
</script>
<style></style>
