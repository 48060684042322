<template>
    <div>
        <layout>
            <div class="container">
                
            </div>
        </layout>
    </div>
</template>

<script>
import LayoutVue from "../../Layouts/Layout.vue";
export default {
    components: {
        Layout: LayoutVue
    }
}
</script>