

const state  = {
    bookings: [],
    booking: null,

}

const mutations = {
    ADD_BOOKING(state, payload){
        state.bookings = [...payload, ...state.bookings]
    },
    EDIT_BOOKING(state, payload){
        state.bookings = state.bookings.map((booking) => booking.id === payload.id ? payload : booking)
    },
    DELETE_BOOKING(state, payload){
        state.bookings = state.bookings.filter((booking) => booking.id != payload.id)
    },
    BOOKINGS(state, payload){
        state.bookings = payload;
    },
    BOOKING(state, payload){
        state.booking = payload;
    },

}

const actions = {
    fetchAllBookings({commit}, payload){
        return new Promise((resolve, reject) => {
            console.log('payload', payload);
            window.axios.post(`/api/admin/fetch-bookings`, payload)
            .then((res) => {
                commit('BOOKINGS', res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err.response.data.message);
                reject(err.response.data.message);
            });
        });
       
        
    },

    fetchBooking({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/fetch-booking/${id}`, {id})
            .then((res) => {
                commit('BOOKING', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
    },
    addBooking({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/add-booking`, payload)
            .then((res) => {
                commit('ADD_BOOKING', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    updateBooking({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/update-booking/${payload.id}`, payload.data)
            .then((res) => {
                commit('EDIT_BOOKING', payload);
                resolve(res.data);

            }).catch((err) => {
                console.log('update error', err.response.data.errors);
                reject(err.response.data.errors);
            });
        });
    },
    editBooking({commit}, id){
        return new Promise((resolve, reject) => {
            window.axios.get(`/api/admin/bookings/${id}/edit`)
            .then((res) => {
                commit('BOOKING', res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
                console.log(err.response.data.message);
            });
        });
         
        
    },
    deleteBooking({commit}, payload){
        return new Promise((resolve, reject) => {
            window.axios.post(`/api/admin/delete-booking`, payload)
            .then((res) => {
                commit('DELETE_BOOKING', res.data);
                resolve(res.data);
                // router.push('/admin/bookings');
            }).catch((err) => {
                console.log('add error', err.message);
                reject(err);
            });
        });
    },

}

const getters = {
    bookings(state){
        return state.bookings;
    },
    bookingDetail(state){
        return state.booking;
    },
  
}

export default {
    state,
    getters,
    mutations,
    actions
}