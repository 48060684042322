var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Argon","title":"Argon"}},[(_vm.$page.user && _vm.$page.user.user_type == 'admin')?_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-chart-bar-32 text-primary',
          path: '/dashboard'
        }}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Products', icon: 'ni ni-bag-17 text-red', path: '/admin/products'}}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Category', icon: 'ni ni-bullet-list-67 text-dark', path: '/admin/categories'}}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Orders', icon: 'ni ni-cart text-yellow', path: '/admin/orders'}}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Booking', icon: 'ni ni-calendar-grid-58 text-blue', path: '/admin/booking'}}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Voucher', icon: 'ni ni-money-coins text-orange', path: '/admin/vouchers'}}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Customers', icon: 'ni ni-single-02 text-green', path: '/admin/customers'}}})],1):_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-chart-bar-32 text-primary',
          path: '/dashboard'
        }}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'Orders', icon: 'ni ni-cart text-yellow', path: '/orders'}}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{name: 'My Account', icon: 'ni ni-single-02 text-green', path: '/account'}}})],1)],2),_vm._v(" "),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_vm._v(" "),_c('div',{on:{"click":_vm.toggleSidebar}},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }